/* link
========================================================== */
.c-comment {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-comment__person {
  min-width: 77px;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  @include media-queries-up(md) {
    min-width: 154px;
    width: 154px;
    height: 154px;
    margin-right: 35px;
  }
}

.c-comment__txtArea {
  position: relative;
  padding: 25px 10px;
  text-align: left;
  font-weight: $weight-medium;
  z-index: 0;
  font-size: 1.1rem;
  @include media-queries-up(md) {
    padding: 55px;
    text-align: center;
    font-size: 1.6rem;
    max-width: 540px;
    font-size: 1.6rem;
  }
  &:before, &:after {
    position: absolute;
    content: "";
    display: block;
    width: 48px;
    height: 41px;
    z-index: $z-index-5;
    @include media-queries-up(md) {
      width: 90px;
      height: 77px;
    }
  }
  &:before {
    top: 0;
    left: 0;
    background: url(#{$img-common-path}bg-icon-quotation-left.png) no-repeat;
    background-size: contain;
  }
  &:after {
    bottom: 0;
    right: 0;
    background: url(#{$img-common-path}bg-icon-quotation-right.png) no-repeat;
    background-size: contain;
  }
}