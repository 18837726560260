/* content
========================================================== */
.is-contFixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.l-content {
  @include media-queries-up(md) {
    min-width: $content-min-width;
  }
  &--top {
    height: 100%;
    @include media-queries-up(md) {
      padding-top: 0;
    }
  }
}

.l-sectSpace {
  padding-top: 50px;
  padding-bottom: 50px;
  @include media-queries-up(md) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  &--small {
    padding-top: 25px;
    padding-bottom: 25px;
    @include media-queries-up(md) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
}

.l-sectTopSpace {
  padding-top: 50px;
  @include media-queries-up(md) {
    padding-top: 80px;
  }
  &--small {
    padding-top: 25px;
    @include media-queries-up(md) {
      padding-top: 60px;
    }
  }
}

.l-sectBottomSpace {
  padding-bottom: 50px;
  @include media-queries-up(md) {
    padding-bottom: 80px;
  }
  &--small {
    padding-bottom: 25px;
    @include media-queries-up(md) {
      padding-bottom: 60px;
    }
  }
}

.l-sideSpace {
  padding-right: 15px;
  padding-left: 15px;
  &--small {
    padding-right: 10px;
    padding-left: 10px;
  }
  &--large {
    padding-right: 20px;
    padding-left: 20px;
  }
  &--xlarge {
    padding-right: 30px;
    padding-left: 30px;
  }
  @include media-queries-only-down(md) {
    &--smNone {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@include media-queries-only-down(md) {
  .l-sm-sideSpace {
    padding-right: 15px;
    padding-left: 15px;
    &--small {
      padding-right: 10px;
      padding-left: 10px;
    }
    &--large {
      padding-right: 20px;
      padding-left: 20px;
    }
    &--xlarge {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}

@include media-queries-up(md) {
  .l-md-sideSpace {
    padding-right: 15px;
    padding-left: 15px;
    &--small {
      padding-right: 10px;
      padding-left: 10px;
    }
    &--large {
      padding-right: 20px;
      padding-left: 20px;
    }
    &--xlarge {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}

.l-contInner {
  padding: 15px;
  &--small {
    padding: 10px;
  }
  &--large {
    padding: 20px;
  }
}

.l-wrap-base {
  @include media-queries-up(md) {
    width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }
}