/* tel
========================================================== */
.c-telBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 43px;
    width: 43px;
    height: 43px;
    background: $prj-pink01;
    color: $white;
    border-radius: 50%;
    font-size: 2.6rem;
    margin-right: 10px;
    @include media-queries-up(md) {
      min-width: 86px;
      width: 86px;
      height: 86px;
      font-size: 5.2rem;
    }
    &--smOnly {
      @include media-queries-up(md) {
        display: none;
      }
    }
  }
  &__name {
    font-size: 1.1rem;
    @include media-queries-up(md) {
      font-size: 1.8rem;
    }
  }
  &__number {
    font-family: $font-type04;
    font-weight: $weight-bold;
    font-size: 2.8rem;
    @include media-queries-up(md) {
      font-size: 4.8rem;
    }
    a {
      color: $prj-pink01;
    }
  }
  &__txt {
    font-size: 1.2rem;
    @include media-queries-up(md) {
      font-size: 1.6rem;
    }
    &--large {
      font-size: 1.2rem;
      @include media-queries-up(md) {
        font-size: 1.8rem;
      }
    }
  }
}

.c-telSimple {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 43px;
    width: 43px;
    height: 43px;
    background: $prj-pink01;
    color: $white;
    border-radius: 50%;
    font-size: 2.6rem;
    margin-right: 10px;
    @include media-queries-up(md) {
      min-width: 86px;
      width: 86px;
      height: 86px;
      font-size: 5.2rem;
    }
  }
  &__inner {
    line-height: 1.4;
  }
  &__number {
    font-family: $font-type04;
    font-weight: $weight-bold;
    font-size: 1.8rem;
    letter-spacing: .03em;
    @include media-queries-up(md) {
      font-size: 3.2rem;
    }
    a {
      color: $prj-pink01;
    }
  }
  &__txt {
    font-size: 1.2rem;
    @include media-queries-up(md) {
      font-size: 2rem;
    }
  }
}