/* media
========================================================== */

// media-service-list
.c-mediaServiceListWrap {
    overflow: hidden;
    @include media-queries-up(md) {
        padding-left: 90px;
        padding-right: 90px;
    }
}

.c-mediaServiceList {
    padding: 0 40px 0 15px;
    white-space: nowrap;
    @include media-queries-up(md) {
        margin-bottom: -40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
        padding: 0;
    }
    &__item {
        width: 94px;
        margin: 0 10px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        @include media-queries-up(md) {
            width: 190px;
            margin: 0 15px 40px;
        }
    }
    &__upside {
        position: relative;
        margin-bottom: 10px;
        @include media-queries-up(md) {
            margin-bottom: 20px;
        }
    }
    &__photo {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        height: 69px;
        @include media-queries-up(md) {
            height: 138px;
        }
        > img {
            width: 100%;
            height: auto;
        }
    }
    &__badge {
        position: absolute;
        right: -5px;
        bottom: -5px;
    }
    &__txt {
        font-size: 1.2rem;
        font-weight: $weight-bold;
        &--exLink {
            &::after {
                @include icon-set;
                margin-left: .5em;
                content: "\e90e";
            }
        }
        @include media-queries-up(md) {
            font-size: 1.4rem;
        }
    }
    a {
        color: $prj-pink03;
        display: block;
        img {
            transition: .3s ease-out;
        }
        &:hover {
            opacity: 1;
            @include media-queries-up(md) {
                .c-mediaServiceList__photo img {
                    transform: scale(1.02);
                    transition: .3s ease-out;
                }
            }
        }
    }
}


// media-banner
.c-mediaBnrWrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include media-queries-up(md) {
        flex-direction: row;
    }
}

.c-mediaBnr__photo {
    width: 100%;
}

.c-mediaBnr__txt {
    margin-top: 10px;
    color: $prj-gray04;
    font-size: 1.2rem;
    line-height: 1.8;
    @include media-queries-up(md) {
        margin-top: 20px;
        font-size: 1.4rem;
    }
}

.c-mediaBnr__icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    @include media-queries-up(md) {
        right: 15px;
        bottom: 15px;
    }
}


// -media-gallery
.c-mediaGallery {
    display: flex;
    @include media-queries-up(md) {
        margin-left: -10px;
        margin-right: -10px;
    }
    &__item {
        margin: 0 5px;
        @include media-queries-up(md) {
            margin: 0 10px;
            width: calc(25% - 20px);
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}


// mediaContLins
.c-mediaContLinks {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
    @include media-queries-up(md) {
        justify-content: center;
    }
    &__item {
        width: calc(50% - 10px);
        margin: 0 5px 25px;
        @include media-queries-up(md) {
            width: 241px;
            margin: 0 20px 30px;
        }
        > a {
            display: block;
            color: inherit;
        }
    }
    &__photo {
        > img {
            width: 100%;
            height: auto;
        }
    }
    &__txt {
        margin-top: 15px;
        @include media-queries-up(md) {
            font-size: 1.6rem;
        }
    }
}

// media-catch-block
.c-mediaCatchBlockWrap {
    .c-mediaBlock:last-child {
        margin-bottom: 0;
    }
}

.c-mediaCatchBlock {
    margin-bottom: 40px;
    @include media-queries-up(md) {
        margin-bottom: 60px;
    }
    &__photo {
        margin-bottom: 10px;
        @include media-queries-up(md) {
            margin-bottom: 20px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    &__name {
        font-size: 1.3rem;
        font-weight: $weight-bold;
        margin-bottom: 5px;
        @include media-queries-up(md) {
            font-size: 1.6rem;
            margin-bottom: 10px;
        }
    }
    ul, ol, p {
        margin-bottom: .5em;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// mediaBlock
.c-mediaBlock {
    &__photoWrap {
        position: relative;
    }
    &__photo {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
            transition: .3s ease-out;
        }
        &:hover {
            opacity: 1;
            @include media-queries-up(md) {
                img {
                    transform: scale(1.02);
                    transition: .3s ease-out;
                }
            }
        }
    }
    &__imgLabel {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $z-index5;
        display: inline-block;
        background: $prj-pink03;
        max-width: 100%;
        img {
            width: auto;
            height: 22px;
            @include media-queries-up(md) {
                height: auto;
            }
        }
    }
    &__labels {
        margin-top: 5px;
        @include media-queries-up(md) {
            margin-top: 15px;
        }
    }
    &__txt {
        margin-top: 15px;
        &--smallSpace {
            margin-top: 5px;
        }
    }
    > a {
        display: block;
        color: inherit;
    }
    a {
        &:hover {
            opacity: 1;
        }
    }
}


// movie-area
.c-mediaVideoArea {
    position: relative;
    width: 100%;
    padding-top: 56.25%; // 16:9
    iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
    }
}
