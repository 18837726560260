/* map
========================================================== */
.c-mapArea {
  display: flex;
  flex-direction: column;
  iframe {
    width: 100%;
    height: 215px;
    @include media-queries-up(md) {
      width: 100%;
      height: 340px;
    }
  }
}