// -----------------------------------------------------------------
// align
// -----------------------------------------------------------------
.u-align-baseline { vertical-align: baseline !important; } // Browser default
.u-align-top { vertical-align: top !important; }
.u-align-middle { vertical-align: middle !important; }
.u-align-bottom { vertical-align: bottom !important; }
.u-align-text-bottom { vertical-align: text-bottom !important; }
.u-align-text-top { vertical-align: text-top !important; }

@include media-queries-only-down(md) {
  .u-sm-align-baseline { vertical-align: baseline !important; } // Browser default
  .u-sm-align-top { vertical-align: top !important; }
  .u-sm-align-middle { vertical-align: middle !important; }
  .u-sm-align-bottom { vertical-align: bottom !important; }
  .u-sm-align-text-bottom { vertical-align: text-bottom !important; }
  .u-sm-align-text-top { vertical-align: text-top !important; }
}
@include media-queries-up(md) {
  .u-md-align-baseline { vertical-align: baseline !important; } // Browser default
  .u-md-align-top { vertical-align: top !important; }
  .u-md-align-middle { vertical-align: middle !important; }
  .u-md-align-bottom { vertical-align: bottom !important; }
  .u-md-align-text-bottom { vertical-align: text-bottom !important; }
  .u-md-align-text-top { vertical-align: text-top !important; }
}