/* full slide
========================================================== */

.p-fullSlide {
    position: relative;
    width: 100%;
    // height: 100%;
    // height: calc(100% - 109px);
    overflow: hidden;
    &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    img {
        width: 100%;
    }
    @include media-queries-up(md) {
        // height: calc(100% - 61px);
        height: 100%;
    }
}

.widthAjast {
    width: 100%;
}

.heightAjast {
    height: 100%;
}

.js-loader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: #fff;
}

.js-SlideReset {
    opacity: 0;
    transform: scale(1.2);
    transform-origin: center center;
}

.js-SlideOpaOne {
    opacity: 1;
    transition: all 5s;
}

.js-SlideScale {
    transform: scale(1);
    transform-origin: center center;
    transition: all 6s;
}

.js-outHeader {
    transform: translateY(-100px);
}

.js-inHeader {
    transform: translateY(0px);
    transition: all 4s cubic-bezier(0.940, 0.240, 0.000, 0.995);
    ;
}

.js-outFooter {
    transform: translateY(100px);
}

.js-inFooter {
    transform: translateY(0px);
    transition: all 4s cubic-bezier(0.940, 0.240, 0.000, 0.995);
    ;
}