/* fixdBtoomNavi
========================================================== */
.p-fixdBtoomNavi {
  position: fixed;
  bottom: 0;
  left: 0;
  background: $prj-pink01;
  display: flex;
  width: 100%;
  z-index: $zindex-fixdBtoomNavi;
  transform: translateY(100%);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  &.is-fixdBtoomNavi--act {
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    ;
    transform: translateY(0px);
  }
  &.p-fixdBtoomNavi--top {
    background: $white;
    transform: translateY(0px);
    .p-fixdBtoomNavi__name {
      border-left: 1px solid $prj-pink01;
    }
    a {
      color: $prj-pink01;
    }
  }
  &__item {
    width: 50%;
    &:first-child .p-fixdBtoomNavi__name {
      border-left: none;
    }
  }
  &__itemInner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
  }
  &__name {
    display: block;
    width: 100%;
    padding: 5px;
    text-align: center;
    border-left: 1px solid $white;
    font-size: 1.3rem;
    font-weight: $weight-medium;
    &::before {
      margin-right: .5em;
      vertical-align: baseline;
    }
  }
  a {
    color: $white;
  }
  @include media-queries-up(md) {
    display: none;
  }
}