/* font
========================================================== */

/*! icon font */
@font-face {
  font-family: "watabe-icon";
  src: url("/common/fonts/watabe-icon.eot?ckxm9t");
  src: url("/common/fonts/watabe-icon.eot?ckxm9t#iefix") format("embedded-opentype"), url("/common/fonts/watabe-icon.ttf?ckxm9t") format("truetype"), url("/common/fonts/watabe-icon.woff?ckxm9t") format("woff"), url("/common/fonts/watabe-icon.svg?ckxm9t#watabe-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*! Noto Sans Japanese (japanese) http://www.google.com/fonts/earlyaccess */
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format("woff2"),
  url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format("woff"),
  url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff2) format("woff2"),
  url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff) format("woff"),
  url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff2) format("woff2"),
  url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff) format("woff"),
  url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2) format("woff2"),
  url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff) format("woff"),
  url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf) format("opentype");
}

// Montserrat
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500,600');

// Lato
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');