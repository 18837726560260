/* aside
========================================================== */
.l-aside {
  padding-top: 50px;
  @include media-queries-up(md) {
    padding-top: 110px;
    min-width: $content-min-width;
  }
}

.l-asideSect {
  padding-top: 35px;
  padding-bottom: 35px;
  @include media-queries-up(md) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}


.l-asideBreadcrumbList {
  border-bottom: 2px solid $prj-gray01;
  padding-bottom: 10px;
  margin-bottom: 15px;
  @include media-queries-up(md) {
    width: 1190px;
    max-width: 100%;
    margin: 0 auto 50px;
    padding-bottom: 15px;
  }
  &__item {
    display: inline-block;
    color: $prj-gray03;
    font-size: 1.1rem;
    @include media-queries-up(md) {
      font-size: 1.6rem;
    }
    &:before {
      content: " > ";
      margin: 0 5px;
    }
    &:first-child:before {
      content: "";
      margin: 0;
    }
  }
  a {
    color: $prj-gray03;
  }
}

// .l-asideSectHead {

// }

.l-asideSectHead__photo {
  margin-bottom: 10px;
  img {
    width: 100%;
    height: auto;
  }
}

.l-asideSectHead__txtBlock {
  color: $prj-gray04;
  letter-spacing: .1em;
  font-size: 1.2rem;
  @include media-queries-up(md) {
    font-size: 1.4rem;
  }
}


.l-asideGuideWrap {
  display: flex;
  margin: 0 auto 30px;
  justify-content: center;
  flex-wrap: wrap;
  @include media-queries-up(md) {
    margin-bottom: 50px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    // justify-content: space-around;
  }
}

.l-asideGuide__tel {
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-queries-up(md) {
     width: 510px;
  }
}