// -----------------------------------------------------------------
// display
// -----------------------------------------------------------------
.u-ds-none         { display: none !important; }
.u-ds-inline       { display: inline !important; }
.u-ds-inblock { display: inline-block !important; }
.u-ds-block        { display: block !important; }
.u-ds-table        { display: table !important; }
.u-ds-table-cell   { display: table-cell !important; }
.u-ds-flex         { display: flex !important; }
.u-ds-inflex  { display: inline-flex !important; }

@include media-queries-only-down(md) {
  .u-sm-ds-none         { display: none !important; }
  .u-sm-ds-inline       { display: inline !important; }
  .u-sm-ds-inblock { display: inline-block !important; }
  .u-sm-ds-block        { display: block !important; }
  .u-sm-ds-table        { display: table !important; }
  .u-sm-ds-table-cell   { display: table-cell !important; }
  .u-sm-ds-flex         { display: flex !important; }
  .u-sm-ds-inflex  { display: inline-flex !important; }
}

@include media-queries-up(md) {
  .u-md-ds-none         { display: none !important; }
  .u-md-ds-inline       { display: inline !important; }
  .u-md-ds-inblock { display: inline-block !important; }
  .u-md-ds-block        { display: block !important; }
  .u-md-ds-table        { display: table !important; }
  .u-md-ds-table-cell   { display: table-cell !important; }
  .u-md-ds-flex         { display: flex !important; }
  .u-md-ds-inflex  { display: inline-flex !important; }
}

.u-sm-none {
  display: none !important;
  @include media-queries-up(md) {
    display: block !important;
  }
}

.u-md-none {
  display: block !important;
  @include media-queries-up(md) {
    display: none !important;
  }
}