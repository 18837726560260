/* card
========================================================== */

.c-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid $prj-gray16;
  background: $white;
  > a {
    min-height: 0%;
    display: block;
    color: $prj-base-text;
  }
  &--flexItem {
    flex-grow: 1;
    min-height: 0%;
  }
  &--heightFull {
    height: 100%;
  }
  &--zoom {
    > a {
      &:hover {
        opacity: 1;
      }
    }
    @include media-queries-up(md) {
      a:hover .c-card__photo img {
        transform: scale(1.02);
        transition: .3s ease-out;
      }
    }
  }
  &--lineNone {
    border: none;
  }
}

.c-card__photo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &--mask-bnrSmall {
    max-height: 105px;
    @include media-queries-up(md) {
      max-height: 170px;
    }
  }
  &--mask-bnr {
    max-height: 105px;
    @include media-queries-up(md) {
      max-height: 224px;
    }
  }
  &--mask-fotCont {
    max-height: 109px;
    @include media-queries-up(md) {
      max-height: 172px;
    }
  }
  &--mask-s {
    max-height: 120px;
    @include media-queries-up(md) {
      max-height: 182px;
    }
  }
  &--mask-m {
    max-height: 210px;
    @include media-queries-up(md) {
      max-height: 290px;
    }
  }
  img {
    width: 100%;
    height: auto;
    transition: .3s ease-out;
  }
}

.c-card__block {
  padding: 10px;
  &--inLarge {
    padding: 10px;
    @include media-queries-up(md) {
      padding: 15px;
    }
    .c-card__icon {
      right: 10px;
      bottom: 10px;
      @include media-queries-up(md) {
        right: 15px;
        bottom: 15px;
      }
    }
    .c-card__linkTxt {
      right: 10px;
      bottom: 10px;
      @include media-queries-up(md) {
        right: 15px;
        bottom: 15px;
      }
    }
  }
  &--iconCenter {
    position: relative;
    padding-right: 25px;
    @include media-queries-up(md) {
      padding-right: 40px;
    }
    .c-card__icon {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &--iconSpace {
    padding-bottom: 30px;
    @include media-queries-up(md) {
      padding-bottom: 40px;
    }
  }
  &--linkTxtSpace {
    padding-bottom: 40px;
    @include media-queries-up(md) {
      padding-bottom: 50px;
    }
  }
  &--iconInSpace {
    padding-right: 25px;
    @include media-queries-up(md) {
      padding-right: 40px;
    }
  }
}

.c-card__labels {
  margin-bottom: 10px;
}

.c-card__txt {
  font-size: 1.3rem;
  @include media-queries-up(md) {
    font-size: 1.4rem;
  }
}


.c-card__icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  background: $prj-pink03;
  color: $white;
  z-index: $z-index5;
  width: 18px;
  height: 18px;
  font-size: 1rem;
  @include media-queries-up(md) {
    width: 27px;
    height: 27px;
    font-size: 1.4rem;
  }
}

.c-card__linkTxt {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  @include media-queries-up(md) {
    font-size: 1.6rem;
  }
}

.c-card__linkIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  background: $prj-pink03;
  color: $white;
  z-index: $z-index5;
  width: 18px;
  height: 18px;
  font-size: 1rem;
  margin-left: .5em;
  @include media-queries-up(md) {
    width: 27px;
    height: 27px;
    font-size: 1.4rem;
  }
}

.c-card__btnWrap {
  padding: 30px 10px 25px;
  text-align: center;
  @include media-queries-up(md) {
      padding: 50px 10px 40px;
  }
  &--noSpace {
    padding-bottom: 0;
    @include media-queries-up(md) {
      padding-bottom: 0;
    }
  }
}

.c-card__telWrap {
  padding: 20px 10px 30px;
  @include media-queries-up(md) {
    padding: 40px 10px 40px;
  }
}

.c-cardSlide {
  &__item {
    @include media-queries-up(md) {
      width: 480px;
      margin: 0 15px;
    }
  }
}


.c-cardGroup {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  @include media-queries-up(md) {
    flex-wrap: nowrap;
  }
}

.c-cardSize {
  &--bnr {
    width: 100%;
    @include media-queries-up(md) {
      max-width: 100%;
      width: 520px;
    }
  }
  &--bnrLarge {
    width: 100%;
    @include media-queries-up(md) {
      max-width: 100%;
      width: 550px;
    }
  }
}

// card report
.c-cardReport {
  a {
    color: $prj-gray07;
    img {
      transition: .3s ease-out;
    }
    &:hover {
      opacity: 1;
      @include media-queries-up(md) {
        .c-cardReport__photo img {
          transform: scale(1.02);
          transition: .3s ease-out;
        }
      }
    }
  }
  &Inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: auto;
    height: 220px;
    overflow: hidden;
    @include media-queries-up(md) {
      width: 497px;
      height: 300px;
    }
  }
  &__info {
    z-index: $z-index5;
    transition: opacity .8s;
    opacity: 0;
    visibility: hidden;
    width: 90%;
    background: $white;
    padding: 15px;
    border-radius: 5px;
    margin-top: -10%;
    margin-bottom: 35px;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
    @include media-queries-up(md) {
      margin-top: -66px;
      margin-bottom: 70px;
      width: 436px;
      padding: 30px;
      box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
    }
  }
  &__data {
    font-size: 1.1rem;
    font-weight: $weight-medium;
     margin-bottom: 1em;
    @include media-queries-up(md) {
      font-size: 1.4rem;
    }
  }
  &__name {
    font-size: 1.3rem;
    margin-bottom: 1em;
    font-style: italic;
    font-weight: $weight-medium;
    color: $prj-pink01;
    @include media-queries-up(md) {
      font-size: 1.8rem;
    }
  }
  &__country {
    font-size: 1.1rem;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
    [class^="c-icon-"],
    [class*=" c-icon-"] {
      margin-right: .2em;
    }
    @include media-queries-up(md) {
      font-size: 1.4rem;
    }
  }
}