/* lead
========================================================== */
.c-catchTxt {
    font-size: 1.7rem;
    font-family: $font-type03;
    color: $prj-pink03;
    text-align: center;
    @include media-queries-up(md) {
      font-size: 2.4rem
    }
}

.c-sectLead {
  @include media-queries-up(md) {
    font-size: 1.8rem
  }
}