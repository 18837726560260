// mixin
// =======================================================
//clearfix
@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

// image replacement
@mixin text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

//icon
@mixin icon-set {
  font-family: 'watabe-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// breakpoint
// @include mq(md);
@mixin media-queries-up($breakpoint: md) {
  @media print, screen and (min-width: #{map-get($grid-breakpoints, $breakpoint)}) {
    @content;
  }
}

@mixin media-queries-down($breakpoint: md) {
  @media screen and (max-width: #{map-get($grid-breakpoints, $breakpoint)}) {
    @content;
  }
}

@mixin media-queries-only-down($breakpoint: md) {
  @media only screen and (max-width: #{map-get($grid-breakpoints, $breakpoint)}) {
    @content;
  }
}

@mixin media-queries-only-up($breakpoint: md) {
  @media print, only screen and (min-width: #{map-get($grid-breakpoints, $breakpoint)}) {
    @content;
  }
}

// container-max-widths
// @include cmw(md);
@mixin cmw($max-widths: md) {
  width: map-get($container-max-widths, $max-widths);
  max-width: 100%;
}
