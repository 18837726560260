/* full slide
========================================================== */
.p-headVis {
  position: relative;
  height: 320px;
  @include media-queries-up(md) {
     height: 462px;
  }
}
.p-headVisInner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  &--sm-left {
    text-align: left;
  }
  @include media-queries-up(md) {
    justify-content: flex-start;
    &--center {
      justify-content: center;
    }
    &--sm-left {
      text-align: center;
    }
  }
}

.p-headVis__txtWrap {
  color: $white;
  padding: 10px 20px;
}

.p-headVis__title {
  font-size: 3rem;
  font-family: $font-type02;
  letter-spacing: .1em;
  line-height: 1.2;
  font-weight: $weight-semiBold;
  @include media-queries-up(md) {
    letter-spacing: .2em;
    font-size: 4.6rem;
  }
}

.p-headVis__subTxt{
  display: block;
  font-size: 1.8rem;
  font-family: $font-type03;
  font-weight: $weight-bold;
  letter-spacing: .1em;
  margin-top: 10px;
  @include media-queries-up(md) {
    font-size: 1.9rem;
    margin-top: 15px;
  }
}