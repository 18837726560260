/* block
========================================================== */

// blok-warap
.c-blockWrap-xs {
  margin-bottom: -10px;
}
.c-blockWrap-s {
  margin-bottom: -20px;
}
.c-blockWrap-m {
  margin-bottom: -40px;
}
.c-blockWrap-l {
  margin-bottom: -60px;
}
@include media-queries-only-down(md) {
  .c-sm-blockWrap-xs {
    margin-bottom: -10px;
  }
  .c-sm-blockWrap-s {
    margin-bottom: -20px;
  }
  .c-sm-blockWrap-m {
    margin-bottom: -40px;
  }
  .c-sm-blockWrap-l {
    margin-bottom: -60px;
  }
}
@include media-queries-up(md) {
  .c-md-blockWrap-xs {
    margin-bottom: -10px;
  }
  .c-md-blockWrap-s {
    margin-bottom: -20px;
  }
  .c-md-blockWrap-m {
    margin-bottom: -40px;
  }
  .c-md-blockWrap-l {
    margin-bottom: -60px;
  }
}

// margin
.c-block-xs {
  margin-bottom: 10px;
}
.c-block-s {
  margin-bottom: 20px;
}
.c-block-m {
  margin-bottom: 40px;
}
.c-block-l {
  margin-bottom: 60px;
}
.c-block-xl {
  margin-bottom: 80px;
}
.c-block-xxl {
  margin-bottom: 100px;
}
@include media-queries-only-down(md) {
  .c-sm-block-xs {
    margin-bottom: 10px;
  }
  .c-sm-block-s {
    margin-bottom: 20px;
  }
  .c-sm-block-m {
    margin-bottom: 40px;
  }
  .c-sm-block-l {
    margin-bottom: 60px;
  }
  .c-sm-block-xl {
    margin-bottom: 80px;
  }
  .c-sm-block-xxl {
    margin-bottom: 100px;
  }
}

@include media-queries-up(md) {
  .c-md-block-xs {
    margin-bottom: 10px;
  }
  .c-md-block-s {
    margin-bottom: 20px;
  }
  .c-md-block-m {
    margin-bottom: 40px;
  }
  .c-md-block-l {
    margin-bottom: 60px;
  }
  .c-md-block-xl {
    margin-bottom: 80px;
  }
  .c-md-block-xxl {
    margin-bottom: 100px;
  }
}

// padding
.c-block-pd-xs {
  padding-bottom: 10px;
}
.c-block-pd-s {
  padding-bottom: 20px;
}
.c-block-pd-m {
  padding-bottom: 40px;
}
.c-block-pd-l {
  padding-bottom: 60px;
}
.c-block-pd-xl {
  padding-bottom: 80px;
}
.c-block-pd-xxl {
  padding-bottom: 100px;
}
@include media-queries-only-down(md) {
  .c-sm-block-pd-xs {
    padding-bottom: 10px;
  }
  .c-sm-block-pd-s {
    padding-bottom: 20px;
  }
  .c-sm-block-pd-m {
    padding-bottom: 40px;
  }
  .c-sm-block-pd-l {
    padding-bottom: 60px;
  }
  .c-sm-block-pd-xl {
    padding-bottom: 80px;
  }
  .c-sm-block-pd-xxl {
    padding-bottom: 100px;
  }
}
  @include media-queries-up(md) {
  .c-md-block-pd-xs {
    padding-bottom: 10px;
  }
  .c-md-block-pd-s {
    padding-bottom: 20px;
  }
  .c-md-block-pd-m {
    padding-bottom: 40px;
  }
  .c-md-block-pd-l {
    padding-bottom: 60px;
  }
  .c-md-block-pd-xl {
    padding-bottom: 80px;
  }
  .c-md-block-pd-xxl {
    padding-bottom: 100px;
  }
}

// layout
.c-block-flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-block-flexLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-block-flexRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.c-block-flexAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.c-block-flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-block-flexColumn {
  display: flex;
  flex-direction: column;
}
@include media-queries-only-down(md) {
  .c-sm-block-flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-sm-block-flexLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .c-sm-block-flexRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .c-sm-block-flexAround {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .c-sm-block-flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@include media-queries-up(md) {
  .c-md-block-flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .c-md-block-flexLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .c-md-block-flexRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .c-md-block-flexAround {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  .c-md-block-flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}