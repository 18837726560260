/* slide
========================================================== */
.p-slide {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @include media-queries-up(md) {
    margin-bottom: 40px;
  }
  &__item {
    &:focus {
      outline: none;
    }
  }
  .slick-dots {
    bottom: -35px;
    @include media-queries-up(md) {
      bottom: -40px;
    }
  }
  a {
    &:focus {
      outline: none;
    }
  }
}


// campaign
.p-slide--camp {
  margin-bottom: 0;
  @include media-queries-up(md) {
    margin-bottom: 40px;
  }
  @include media-queries-only-down(md) {
    .slick-slide {
      transform: scale(.8);
      transition: .2s linear;
      z-index: $z-index5;
      margin-right: -15px;
      margin-left: -15px;
    }
    .slick-current {
      position: relative;
      transform: scale(1);
      transition: .2s linear;
      transition-delay: .4s;
      z-index: $z-index10;
      box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .p-slide__item {
    width: 240px;
    margin-bottom: 35px;
    @include media-queries-up(md) {
      width: 467px;
      margin: 0 15px;
    }
  }
  .slick-dots {
    bottom: -5px;
    @include media-queries-up(md) {
      bottom: -40px;
    }
  }
}

// card
.p-slide--card {
  .p-slide__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 15px;
    @include media-queries-up(md) {
      width: 480px;
      flex-basis: auto;
    }
  }
  .slick-slide {
    height: auto;
  }
  .slick-track {
    display: flex;
    flex-grow: 1;
  }
  .c-card {
    display: flex;
    flex-grow: 1;
  }
}

// headvis
.p-slide--headvis {
  margin-bottom: 40px;
  @include media-queries-up(md) {
    margin-bottom: 70px;
  }
  .p-slide__item {
    width: 100%;
    position: relative;
    margin: 0 5px;
    @include media-queries-up(md) {
      margin: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__itemInner {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @include media-queries-up(md) {
      height: 511px;
    }
  }
  .slick-dots {
    @include media-queries-up(md) {
      bottom: -40px;
    }
  }
}

// area
.p-slide--area {
  .p-slide__item {
    width: 100%;
    margin: 0 5px;
    @include media-queries-up(md) {
      width: 688px;
      margin: 0 10px;
    }
  }
}

// report
.p-slide--report {
  .p-slide__item {
    margin: 0 10px;
    @include media-queries-up(md) {
      margin: 0 15px;
    }
  }
  .slick-current {
    .c-cardReport__info {
      opacity: 1;
      visibility: visible;
      transition: opacity .3s;
      transition-delay: .6s;
    }
  }
  .slick-dots {
    bottom: 0;
  }
  .slick-prev,
  .slick-next {
    top: 156px;
  }
}

// salon
.p-slide--saron {
  margin-bottom: 0;
  .p-slide__item {
    width: 100%;
    margin: 0 5px;
    @include media-queries-up(md) {
      width: 690px;
      margin: 0 10px;
    }
  }
  .slick-dots {
    display: none !important;
    @include media-queries-up(md) {
      display: block !important;
    }
  }
}
