/* select-circle
========================================================== */
.p-selectCircleWrap {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  @include media-queries-up(md) {
    max-width: 955px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 860px;
  }
}

.p-selectCircle {
  width: calc(50% - 16px);
  margin-left: 32px;
  margin: 20px 0 20px 32px;
  text-align: center;
  &:nth-child(2n) {
    margin-left: 0;
  }
  &--1st {
    width: 80%;
    margin: 0 auto 20px;
    .p-selectCircle__info {
      display: flex;
    }
    .p-selectCircle__arrow {
      margin-left: 10px;
    }
  }
  @include media-queries-up(md) {
    position: absolute;
    text-align: left;
    margin: 0;
    &--1st {
      width: 414px;
      top: 0;
      left: 82px;
      margin: 0;
      .p-selectCircle__info {
        margin-left: -5em;
      }
      .p-selectCircleInner {
        align-items: center;
      }
    }
    &--2nd {
      width: 248px;
      top: 183px;
      left: 640px;
    }
    &--3rd {
      width: 248px;
      top: 546px;
      left: 0;
    }
    &--4th {
      width: 174px;
      top: 553px;
      left: 380px;
      .p-selectCircle__info {
        margin-right: -8em;
      }
    }
    &--5th {
      width: 174px;
      top: 602px;
      left: 707px;
    }
  }
  a:hover {
    opacity: 1;
  }
}

.p-selectCircleInner {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include media-queries-up(md) {
    align-items: flex-start;
  }
}

.p-selectCircle__photo {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 15px;
  img {
    width: 100%;
    height: auto;
  }
  @include media-queries-up(md) {
    img {
      transition: .3s ease-out;
    }
    &:hover {
      img {
        transform: scale(1.02);
        transition: .3s ease-out;
      }
    }
  }
}

.p-selectCircle__info {
  @include media-queries-up(md) {
    display: flex;
    margin-right: -5em;
  }
}

.p-selectCircle__subName {
  color: $prj-pink03;
  font-size: 1rem;
  font-family: $font-type02;
  font-weight: $weight-semiBold;
  @include media-queries-up(md) {
    font-size: 1.4rem;
  }
}

.p-selectCircle__name {
  font-size: 1.3rem;
  color: $prj-gray07;
  font-weight: $weight-medium;
  @include media-queries-up(md) {
    font-size: 1.6rem;
  }
}

.p-selectCircle__arrow {
  display: inline-flex;
  align-self: flex-end;
  @include media-queries-up(md) {
    margin-left: 20px;
  }
}