/* title
========================================================== */
.c-pageTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $prj-gray15;
  color: $prj-pink03;
  height: 40px;
  font-family: $font-type03;
  font-weight: $weight-bold;
  font-size: 1.4rem;
}
.c-sectTitle {
  font-family: $font-type02;
  display: block;
  text-align: center;
  font-size: 2.7rem;
  font-weight: $weight-semiBold;
  letter-spacing: .12em;
  line-height: 1.2;
  margin-bottom: 35px;
  color: $prj-pink02;
  @include media-queries-up(md) {
    font-size: 4.2rem;
    margin-bottom: 50px;
  }
  &__subTxt {
    margin-top: 10px;
    display: block;
    font-size: 1.2rem;
    font-family: $font-type03;
    font-weight: $weight-medium;
    color: $prj-gray07;
    letter-spacing: .06em;
    line-height: 1;
    @include media-queries-up(md) {
      margin-top: 20px;
      font-size: 1.8rem;
    }
  }
  &--light {
    font-weight: $weight-regular;
    font-size: 1.8rem;
    color: $prj-gray07;
    @include media-queries-up(md) {
      font-size: 4rem;
    }
    .c-sectTitle__subTxt {
      color: $prj-gray04;
      font-size: 1.2rem;
      @include media-queries-up(md) {
        font-size: 2rem;
      }
    }
  }
}
.c-sectDtrTitle {
  display: block;
  text-align: center;
  font-family: $font-type02;
  font-weight: $weight-bold;
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: $prj-gray07;
  @include media-queries-up(md) {
    font-size: 3.4rem;
    margin-bottom: 40px;
  }
  &__subTxt {
    display: block;
    font-size: 1.2rem;
    font-family: $font-type03;
    font-weight: $weight-medium;
    @include media-queries-up(md) {
      font-size: 1.8rem;
    }
  }
}

.c-sectInTitle {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  @include media-queries-up(md) {
    margin-bottom: 60px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: $prj-pink03;
    color: $white;
    border-radius: 50%;
    margin: 0 auto 10px;
    font-size: 1.6rem;
    @include media-queries-up(md) {
      width: 47px;
      height: 47px;
      font-size: 3rem;
    }
  }
  &__name {
    font-size: 1.8rem;
    font-family: $font-type02;
    font-weight: $weight-semiBold;
    color: $prj-pink03;
    padding-bottom: 15px;
    letter-spacing: .06em;
    @include media-queries-up(md) {
      padding-bottom: 25px;
      font-size: 3.4rem;
    }
  }
  &__subTxt {
    font-size: 1.2rem;
    display: block;
    font-family: $font-type03;
    font-weight: $weight-medium;
    color: $prj-gray07;
    @include media-queries-up(md) {
      font-size: 1.8rem;
    }
  }
  &::after {
    position: absolute;
    left: 50%;
    bottom: 0;
    content: "";
    width: 24px;
    height: 1.5px;
    margin-left: -12px;
    background: $prj-pink03;
    @include media-queries-up(md) {
      width: 46px;
      height: 3px;
      margin-left: -23px;
    }
  }
  &--mdLarge {
    @include media-queries-up(md) {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      .c-sectInTitle__icon {
        min-width: 39px;
        width: 39px;
        height: 39px;
        margin: 0;
        font-size: 2.6rem;
        margin-right: 10px;
      }
      .c-sectInTitle__name {
        display: inline-block;
        padding-bottom: 10px;
        font-size: 2.6rem;
        line-height: 1;
        letter-spacing: .08em;
      }
      .c-sectInTitle__subTxt {
        display: inline-block;
        font-size: 1.4rem;
        margin-left: .5em;
      }
      &::after {
        width: calc(100% - 49px);
        height: 2px;
        margin-left: 0;
        left: 49px;
        bottom: 5px;
      }
    }
  }
}

.c-titleArt {
  font-family: $font-type03;
  color: $prj-gray07;
  font-weight: $weight-bold;
  &--large {
    font-size: 1.7rem;
    letter-spacing: .08em;
    @include media-queries-up(md) {
      font-size: 3.4rem
    }
  }
}

.c-titleArtEx {
  font-family: $font-type03;
  font-weight: $weight-bold;
  text-align: center;
  color: $prj-gray04;
  margin-bottom: 45px;
  @include media-queries-up(md) {
    margin-bottom: 25px;
  }
  &__catch {
    position: relative;
    font-size: 1.2rem;
    padding-bottom: 12px;
    margin-bottom: 20px;
    display: block;
    @include media-queries-up(md) {
      font-size: 2rem;
      padding-bottom: 22px;
      margin-bottom: 30px;
    }
    &::after {
      position: absolute;
      left: 50%;
      bottom: 0;
      content: "";
      width: 30px;
      height: 1.5px;
      margin-left: -12px;
      background: $prj-gray07;
      @include media-queries-up(md) {
        width: 46px;
        height: 3px;
        margin-left: -23px;
      }
    }
  }
  &__name {
    font-size: 3rem;
    font-family: $font-type02;
    font-weight: $weight-semiBold;
    line-height: 1.2;
    margin-bottom: 10px;
    display: block;
    @include media-queries-up(md) {
      font-size: 4.6rem;
      margin-bottom: 20px;
    }
  }
  &__subTxt {
    font-size: 1.2rem;
    line-height: 1.2;
    display: block;
    @include media-queries-up(md) {
      font-size: 2.4rem
    }
  }
}