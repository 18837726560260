/* anime
========================================================== */

.c-fadeIn {
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s;
}

.c-fadeInDone {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s;
}