/* modal
========================================================== */
// .is-modalOpen {
//   overflow: hidden;
//   position: fixed;
//   width: 100%;
//   height: 100%;
// }

.p-modalBackdrop {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: $prj-translucentBlack;
  opacity: 0;
  transition: opacity .15s linear;
  &.is-show {
    opacity: 1;
  }
}

// .is-modalOpen .p-modal {
//   overflow-x: hidden;
//   overflow-y: auto;
// }

.p-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  overflow: hidden;
  outline: 0;
  opacity: 0;
  transition: opacity .15s linear;
  transition: transform .4s ease-out;
  transform: translate(0, -1%);
  overflow-x: hidden;
  overflow-y: auto;
  // padding: 0 15px;
}
.p-modal.is-show {
  opacity: 1;
  display: block;
  // .p-modal__dialog {
    transform: translate(0, 0);
    transition: transform .3s ease-out;
  // }
}
.p-modal__dialog {
    max-width: 100%;
    // margin: 20px auto;
    position: relative;
    width: auto;
    // transition: transform .3s ease-out;
    // transform: translate(0, -1%);
    @include media-queries-up(md) {
      // margin: 45px auto;
      width:1000px;
    }
}

.p-modal__content {
  position: relative;
  background: $white;
}

.p-modal__closeBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
  right: 15px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid $prj-pink03;
  border-radius: 50%;
  color: $prj-pink03;
  @include media-queries-up(md) {
    top: 30px;
    right: 30px;
    border-width: 2px;
    font-size: 2rem;
    width: 40px;
    height: 40px;
    &:hover {
      opacity: .8;
    }
  }
}

// cv area
.p-modalCvCont {
  @include media-queries-up(md) {
    width: 1000px;
    // margin: 45px auto;
  }
}

.p-modalCvCont__head {
  text-align: center;
  padding: 40px 20px 20px;
  @include media-queries-up(md) {
    padding: 60px 20px;
  }
}

.p-modalCvCont__headTitle {
  font-size: 2.2rem;
  line-height: 1.4;
  @include media-queries-up(md) {
    font-size: 3.3rem;
  }
}


.p-modalCvNaviWrap {
  padding: 0 20px;
  margin-bottom: 10px;
  @include media-queries-up(md) {
    padding: 0 50px;
    margin-bottom: 20px;
  }
}
.p-modalCvNavi {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: calc(50% - 5px);
    margin-right: 10px;
    background: $prj-pink03;
    border-radius: 4px;
    &:nth-child(2n) {
      margin-right: 0;
    }
    @include media-queries-up(md) {
      width: calc(50% - 20px);
      margin-right: 20px;
    }
    a {
      color: $white;
    }
  }
  &__itemInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    @include media-queries-up(md) {
      flex-direction: row;
      padding: 20px;
      height: 140px;
    }
  }
}
.p-modalCvNavi__icon {
    display: inline-flex;
    font-size: 3rem;
    margin-bottom: 10px;
    @include media-queries-up(md) {
      font-size: 4.6rem;
      margin-right: 10px;
      margin-bottom: 0;
    }
}
.p-modalCvNavi__txtWrap {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1.4;
  @include media-queries-up(md) {
    text-align: left;
  }
}
.p-modalCvNavi__title {
  font-size: 1.4rem;
  margin-bottom: 5px;
    @include media-queries-up(md) {
      font-size: 2.2rem;
    }
}
.p-modalCvNavi__subTxt {
  font-size: 1.1rem;
  @include media-queries-up(md) {
    font-size: 1.4rem;
  }
}

.p-modalCvSubNaviWrap {
  padding: 0 20px;
  margin-bottom: 10px;
  @include media-queries-up(md) {
    padding: 0 50px;
    margin-bottom: 40px;
  }
}

.p-modalCvSubNavi {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @include media-queries-up(md) {
    flex-direction: row;
  }
}

.p-modalCvSubNavi__item {
  width: 100%;
  border: 1px solid $prj-gray09;
  border-radius: 4px;
  margin-bottom: 10px;
  a {
    color: $prj-gray07;
  }
  @include media-queries-up(md) {
    width: calc(33.3% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.p-modalCvSubNavi__itemInner {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  line-height: 1.4;
  @include media-queries-up(md) {
    padding: 10px;
    height: 80px;
    justify-content: center;
  }
}
.p-modalCvSubNavi__icon {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    display: inline-flex;
    font-size: 3rem;
    @include media-queries-up(md) {
      left: 20px;
      font-size: 4.6rem;
    }
}

.p-modalCvSubNavi__txt {
  font-size: 1.4rem;
  padding-left: 75px;
  @include media-queries-up(md) {
    font-size: 2rem;
    padding-left: 40px;
  }
}

.p-modalCvOtherLinkWrap {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  @include media-queries-up(md) {
    padding: 0 20px 60px;
  }
}

.p-modalCvOtherLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media-queries-up(md) {
    justify-content: center;
    flex-direction: row;
  }
}

.p-modalCvOtherLink__item {
  font-size: 1.1rem;
  margin-bottom: 15px;
  font-weight: bold;
  a {
    color: $prj-gray07;
  }
  @include media-queries-up(md) {
    font-size: 1.4rem;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.p-modalCvOtherLink__icon {
  margin-left: 5px;
}

.p-modalCvTel {
  @include media-queries-up(md) {
    order: -1;
  }
}

.p-modalCvTel {
  .c-telBlock__icon {
    min-width: 25px;
    width: 25px;
    height: 25px;
    font-size: 1.4rem;
    @include media-queries-up(md) {
        min-width: 50px;
        width: 50px;
        height: 50px;
        font-size: 2.8rem;
    }
  }
  @include media-queries-up(md) {
    margin-bottom: 25px;
  }
}
