/* link
========================================================== */
.c-linkBlock {
  display: block;
  height: 100%;
}

.c-txtLink {
  color: inherit;
  text-decoration: underline;
  &--blank {
    position: relative;
    margin-right: 1.8em;
    &::after {
      position: absolute;
      right: -1.5em;
      bottom: .1em;
      @include icon-set;
      content: "\e90e";
    }
  }
}

.c-telLink {
  @include media-queries-up(md) {
    color: inherit;
    cursor: default;
    &:hover {
      opacity: 1;
    }
  }
}