/* header
========================================================== */

.l-header {
    position: relative;
    width: 100%;
    background: $prj-translucentWhite;
    @include media-queries-up(md) {
        min-width: $content-min-width;
        height: 110px;
    }
    &--top {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $zindex-topHeader;
        @include media-queries-up(md) {
            height: 70px;
        }
    }
    &--noneNavi {
        @include media-queries-up(md) {
            height: 70px;
        }
    }
}

.l-headerInner {
    position: relative;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid $prj-gray11;
    @include media-queries-up(md) {
        position: absolute;
        top: 0;
        left: 0;
        height: 70px;
        border-bottom: none;
    }
}

.l-headerLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 133px;
    height: 25px;
    margin-top: calc(-25px / 2);
    margin-left: calc(-133px / 2);
    a {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    img {
        width: 100%;
        height: auto;
    }
    @include media-queries-up(md) {
        width: 226px;
        height: 42px;
        margin-left: calc(-226px / 2);
        margin-top: calc(-42px / 2);
    }
}

.l-headerSelectBtns {
    display: none;
    @include media-queries-up(md) {
        position: absolute;
        top: 22px;
        left: 40px;
        display: flex;
        align-items: center;
    }
}

.l-headerSelectBtns__item {
    overflow: hidden;
    display: block;
    width: 30px;
    white-space: nowrap;
    margin-right: 20px;
    line-height: 1;
    transition: width 1s;
    &:hover {
        width: 8em;
    }
    &:nth-child(3) {
        &:hover {
            width: 8.5em;
        }
    }
    &:nth-child(4) {
        &:hover {
            width: 12em;
        }
    }
    &:nth-child(5) {
        &:hover {
            width: 7em;
        }
    }
    a {
        color: $prj-gray10;
        &:hover {
            color: $prj-gray07;
        }
    }
}

.l-headerSelectBtns__itemInner {
    display: flex;
    align-items: center;
}

.l-headerSelectBtns__icon {
    @include media-queries-up(md) {
        font-size: 2.6rem;
        margin-right: .2em;
    }
}

.l-headerLinkBtnsWrap {
    display: none;
    @include media-queries-up(md) {
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 70px;
        padding: 0 48px;
        z-index: $zindex-fixdBtoomNavi;
        background: rgba(255, 255, 255, 0);
        transition: background 0.5s;
        &.is-layer {
            width: 100%;
            background: rgba(255, 255, 255, 0.7);
        }
    }
    &--top {
        @include media-queries-up(md) {
            &.is-layer {
                width: auto;
                background: none;
            }
        }
    }
}

.l-headerLinkBtns {
    display: flex;
}

.l-headerLinkBtns__item {
    @include media-queries-up(md) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 20px;
    }
    a {
        color: $prj-pink01;
    }
}

.l-headerLinkBtns__icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: $prj-pink01;
    color: $white;
    margin-bottom: 5px;
}

.l-headerLinkBtns__txt {
    display: block;
    font-size: 1.1rem;
}

// sp-menu
// ---------------------------------------------
.l-headerMenuBtn {
    position: absolute;
    top: 12px;
    left: 20px;
    cursor: pointer;
    display: inline-block;
    &::before {
        @include icon-set;
        content: "\e911";
        color: $prj-pink04;
        font-size: 2.5rem;
    }
    &.is-open {
        &::before {
            content: "\e918";
            color: $prj-gray08;
        }
    }
    @include media-queries-up(md) {
        display: none;
    }
}

.l-headerMenuWrap {
    position: fixed;
    top: 48px;
    left: 0;
    height: calc(100% - 48px);
    z-index: $zindex-headerMenu;
    background: $white;
    @include media-queries-up(md) {
        display: none;
    }
}

.l-headerMenu {
    height: calc(100% - 140px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    a {
        color: $prj-gray07;
    }
}

.l-menuCategory,
.l-menuPages {
    position: relative;
    border-bottom: 1px solid $prj-gray09;
    &--guide {
        >a {
            color: $prj-pink01;
            &.l-menuCategory__name--arrow,
            &.l-menuCategory__name--exLink {
                &:after {
                    color: $prj-pink01;
                }
            }
        }
    }
    &__icon {
        font-size: 2rem;
        margin-right: 10px;
    }
    &__name {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 10px 20px;
        font-size: 1.4rem;
        &--arrow {
            &:after {
                font-size: 1.2rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                @include icon-set;
                content: "\e903";
                color: $prj-gray12;
            }
        }
        &--exLink {
            &:after {
                font-size: 1.2rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                @include icon-set;
                content: "\e90e";
                color: $prj-gray12;
            }
        }
    }
    &__subName {
        font-size: 1rem;
        margin-left: 1em;
    }
    a {
        position: relative;
    }
}

.l-menuPages__name {
    padding-left: 55px;
}

.l-menuBack {
    background: $prj-gray06;
    font-size: 1.4rem;
    a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 58px;
        &:before {
            font-size: 1.2rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            @include icon-set;
            content: "\e902";
            color: $prj-gray12;
        }
    }
}

.l-headerInfo {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 15px 10px;
    height: 140px;
    background: $white;
    z-index: $zindex-headerMenu-tel;
}

.l-headerInfo__tel {
    line-height: 1;
    margin-bottom: 5px;
}

.l-headerInfo__txt {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 5px;
    color: $prj-gray13;
    letter-spacing: .08em;
}

.l-headerInfo__telInner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.l-headerInfo__telTime {
    display: inline-block;
    font-size: 1rem;
    text-align: center;
    margin-left: 10px;
    line-height: 1.2;
    color: $prj-gray13;
}

.l-headerInfo__number {
    font-family: $font-type04;
    font-size: 2.4rem;
    color: $prj-pink01;
    font-weight: $weight-bold;
    letter-spacing: .06em;
}

.l-headerInfo__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 31px;
    height: 31px;
    color: $white;
    background: $prj-pink01;
    margin-left: 10px;
    font-size: 2rem;
}

.l-headerInfo__bnr {
    display: flex;
    flex-wrap: wrap;
    &Item {
        width: calc(50% - 3px);
        margin-right: 6px;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    &ItemInner {
        width: 100%;
        max-height: 55px;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: auto;
        }
    }
}

//// DrillNavi
.l-headerInner {
    background: $white;
}

.l-headerMenuWrap-close {
    left: -9999px;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transform: translateZ(0);
    transition: opacity .35s cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

.l-headerMenuWrap-active {
    left: 0;
    opacity: 1;
    z-index: 2000;
    visibility: visible;
    transform: all;
    transition: opacity .35s cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

.l-menuCategoryItem {
    position: fixed;
    top: 0;
    left: 100vw;
    visibility: hidden;
    transform: translateX(0vw);
    transition: all .4s cubic-bezier(0.190, 1.000, 0.220, 1.000);
    width: 100vw;
    height: 100vh;
    padding-bottom: 100%;
    overflow-y: auto;
    background: $white;
    z-index: 2;
}

.l-menuCategoryItem-active {
    transform: translateX(-100vw);
    visibility: visible;
}

//// End DrillNavi
