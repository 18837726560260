/* table
========================================================== */
.c-tableStriped {
  background: $white;
  tbody tr:nth-of-type(odd) {
    background-color: $prj-gray09;
  }
  table , td, th {
    border-color: $prj-gray11;
  }
  th {
    color: $prj-brown02;
    text-align: left;
    font-family: $font-type03;
    font-weight: $weight-medium;
    @include media-queries-up(md) {
      text-align: center;
      font-size: 1.6rem;
    }
  }
}

.c-tableInner {
  &--large {
    padding: 15px;
    @include media-queries-up(md) {
      // padding: 35px 50px;
      padding: 20px 50px;
    }
  }
}