// -----------------------------------------------------------------
// border
// -----------------------------------------------------------------
.u-border-none { border: none !important; }
.u-border-topNone { border-top: none !important; }
.u-border-rightNone { border-right: none !important; }
.u-border-bottomNone { border-bottom: none !important; }
.u-border-leftNone { border-left: none !important; }

@include media-queries-only-down(md) {
  .u-sm-border-none { border: none !important; }
  .u-sm-border-topNone { border-top: none !important; }
  .u-sm-border-rightNone { border-right: none !important; }
  .u-sm-border-bottomNone { border-bottom: none !important; }
  .u-sm-border-leftNone { border-left: none !important; }
}

@include media-queries-up(md) {
  .u-md-border-none { border: none !important; }
  .u-md-border-topNone { border-top: none !important; }
  .u-md-border-rightNone { border-right: none !important; }
  .u-md-border-bottomNone { border-bottom: none !important; }
  .u-md-border-leftNone { border-left: none !important; }
}