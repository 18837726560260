/* calendar
========================================================== */
.p-calendarWrap {
  position: relative;
}
.p-calendar {
  position: relative;
  color: $prj-gray18;
  border: 1px solid $white;
  td, th {
    padding: 0;
    border: 1px solid $white;
  }
  th {
    &:nth-of-type(6n) .p-calInner {
      color: $prj-blue02;
    }
    &:nth-of-type(7n) .p-calInner {
      color: $prj-pink03;
    }
  }
  td {
    &:nth-of-type(6n) .p-calInner {
      background: $prj-blue01;
      color: $prj-blue02;
    }
    &:nth-of-type(7n) .p-calInner {
      background: $prj-pink09;
      color: $prj-pink03;
    }
  }
}
.p-calCol {
  width: 14.285%;
  &--sat {
    .p-calInner {
      background: $prj-blue01;
      color: $prj-blue02;
    }
  }
}
.p-calWeek {
  font-family: $font-type02;
  text-align: center;
  font-size: 1.2rem;
  @include media-queries-up(md) {
    font-size: 2rem;
  }
  .p-calInner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    height: 30px;
    word-break: break-all;
    @include media-queries-up(md) {
      height: 60px;
    }
  }
}
.p-calInner {
  border-radius: 3px;
  background: $prj-gray15;
  height: 50px;
  @include media-queries-up(md) {
    height: 145px;
  }
}
.p-calDay {
  display: block;
  font-family: $font-type04;
  line-height: 1;
  font-size: 1.2rem;
  padding: 5px;
  @include media-queries-up(md) {
    padding: 5px 10px;
    font-size: 2rem;
  }
}
.p-calDayCont {
  display: none;
  font-size: 1.2rem;
  padding: 5px;
  a {
    color: inherit;
    text-decoration: underline;
  }
  @include media-queries-up(md) {
    display: block;
  }
}
.p-calDayContEx {
  display: none;
  font-size: 1.2rem;
  padding: 5px;
  &__inner {
    background: $white;
  }
  &__label {
    display: inline-block;
    background: $prj-pink03;
    color: $white;
    line-height: 1;
    margin-top: 5px;
    padding: 5px;
    &[class*=" c-icon-"]:before,
    &[class^=c-icon-]:before {
      margin-right: .5em;
      vertical-align: baseline;
    }
  }
  &__txt {
    padding: 5px;
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
  @include media-queries-up(md) {
    display: block;
  }
}
.p-calDatCotCount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: $prj-gray13;
  a {
    color: $prj-gray13;
    text-decoration: underline;
  }
  @include media-queries-up(md) {
    display: none;
  }
}

.p-calPop {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: $zindex-calendarPop;
  width: 300px;
  font-size: 1.2rem;
  box-shadow:0px 4px 4px 0px rgba(90, 90, 90, 0.12);
  display: none;
  @include media-queries-up(md) {
    &.is-show {
      display: block;
    }
  }
}
.p-calPop__head {
  position: relative;
  background: $prj-pink03;
  color: $white;
  padding: 5px 30px 5px 10px;
}
.p-calPop__cont {
  background: $white;
  padding: 12px 10px;
  p {
    margin-bottom: .5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
}
.p-calPop__close {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 1.6rem;
  line-height: 1;
  cursor: pointer;
}