/* footer
========================================================== */

// top-footer
.l-topFooter {
  position: fixed;
  bottom: 48px;
  left: 0;
  width: 100%;
  height: 61px;
  background: $prj-pink01;
  z-index: $zindex-topFeader;
  @include media-queries-up(md) {
    min-width: $content-min-width;
    bottom: 0;
  }
}

.l-topFooterNavi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  @include media-queries-up(md) {
    justify-content: center;
  }
  &__item {
    font-size: 1rem;
    transform: scale(0.9);
    @include media-queries-up(md) {
      transform: scale(1);
      font-size: 1.4rem;
      margin: 0 25px;
    }
  }
  &__itemInner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 61px;
    @include media-queries-up(md) {
      flex-direction: row;
    }
  }
  &__icon {
    margin-bottom: 5px;
    &:before {
      font-size: 2.5rem;
    }
    &--iconSpace {
      margin-right: 1.3em;
      display: block;
        @include media-queries-up(md) {
          margin-right: 0;
        }
    }
    @include media-queries-up(md) {
      font-size: 3.2rem;
      margin-right: 10px;
      &:before {
        margin-bottom: 0;
      }
    }
  }
  &__name {
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    &--exLink {
      position: relative;
      &::after {
        @include icon-set;
        margin-left: .3em;
        content: "\e90e";
        display: inline-block;
      }
    }
  }
  a {
    color: $white;
  }
}


.l-footer {
  &--fixBtnSpace {
    padding-bottom: 48px;
    @include media-queries-up(md) {
      min-width: $content-min-width;
      padding-bottom: 0;
    }
  }
}

.l-footerNaviArea {
  position: relative;
  padding: 40px 10px 20px;
  border-top: 1px solid $prj-gray09;
  @include media-queries-up(md) {
    padding: 70px 0 20px;
  }
}

.l-footerTopScroll {
  position: fixed;
  right: 10px;
  bottom: 70px;
  background: rgba(36, 37, 43, 0.3);
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  transition: opacity .3s;
  z-index: $zindex-topScrollBtn;
  &.is-show {
    visibility: visible;
    transition: opacity .3s;
    width: 50px;
    height: 50px;
    display: block;
    opacity: 1;
  }
  @include media-queries-up(md) {
    right: 60px;
    bottom: 70px;
    width: 0;
    height: 0;
    border-radius: 6px;
    &.is-show {
      width: 70px;
      height: 70px;
    }
  }
  &__item {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    opacity: 1;
    width: 14px;
    height: 14px;
    transform: rotate(-45deg);
    margin-top: -3px;
    margin-left: -7px;
    @include media-queries-up(md) {
      border-width: 3px;
      width: 18px;
      height: 18px;
      margin-top: -4px;
      margin-left: -9px;
    }
  }
}

.l-footerNaviAreaUpper {
  @include media-queries-up(md) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.l-footerSnsBlock {
  margin-bottom: 50px;
  &__title {
    max-width: 210px;
    margin: 0 auto 25px;
    overflow: hidden;
    text-align: center;
    letter-spacing: .1em;
    font-family: $font-type02;
    color: $prj-brown01;
    white-space: nowrap;
    font-size: 1.6rem;
    span {
      position: relative;
      display: inline-block;
      padding: 0 10px;
      text-align: left;
      &::before,
      &::after {
        position: absolute;
        top: calc(50% - 2px);
        content: '';
        width: 200%;
        height: 2px;
        background-color: $prj-brown01;
      }
      &::before {
        right: 100%;
      }
      &::after {
        left: 100%;
      }
    }
  }
  @include media-queries-up(md) {
    margin-top: 45px;
    margin-bottom: 0;
    &__title {
      max-width: none;
      width: 350px;
      font-size: 2.4rem;
    }
  }
}

.l-footerSnsBtns {
  display: flex;
  justify-content: center;
  &__item {
    margin-left: 40px;
    &:first-child {
      margin-left: 0;
    }
  }
  a {
    font-size: 2.4rem;
    color: $prj-brown01;
  }
  @include media-queries-up(md) {
    &__item {
      margin-left: 60px;
    }
    a {
      font-size: 3.4rem;
    }
  }
}

.l-footerCategoryWrap {
  @include media-queries-up(md) {
    display: flex;
    order: -1;
  }
}

.l-footerCategory {
  @include media-queries-up(md) {
    margin-right: 60px;
    &:last-child {
      margin-right: 10px;
    }
  }
}

.l-footerCategoryList {
  margin-bottom: 50px;
  @include media-queries-up(md) {
    margin-bottom: 0;
  }
}

.l-footerCategoryTitle {
  font-size: 1.3rem;
  color: $prj-gray07;
  font-weight: $weight-demiLight;
  font-family: $font-type02;
  letter-spacing: .1em;
  text-align: center;
  margin-bottom: 15px;
  @include media-queries-up(md) {
    font-size: 1.8rem;
    white-space: nowrap;
    margin-bottom: 50px;
    text-align: left;
  }
}

.l-footerCategoryList {
  display: flex;
  flex-wrap: wrap;
  &__item {
    background: $prj-gray06;
    font-size: 1.1rem;
    text-align: center;
    width: calc(50% - 1px);
    margin-right: 1px;
    margin-bottom: 1px;
    &:nth-child(2n) {
      margin-right: 0;
    }
    @include media-queries-up(md) {
      font-size: 1.2rem;
    }
  }
  a {
    display: block;
    padding: 10px;
    color: $prj-gray04;
  }
  @include media-queries-up(md) {
    display: block;
    &__item {
      font-size: 1.2rem;
      margin-bottom: 15px;
      background: none;
      text-align: left;
      width: auto;
    }
    a {
      padding: 0;
    }
  }
}

.l-footerInfo {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  &__item {
    font-size: 1.1rem;
    @include media-queries-up(md) {
      font-size: 1.2rem;
    }
  }
  a {
    color: $prj-gray04;
  }
  @include media-queries-up(md) {
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
    &__item {
      width: 250px;
      border-left: 1px solid $prj-gray05;
      font-size: 1.2rem;
      padding: 5px;
      text-align: center;
      &:first-child {
        border-left: none;
      }
    }
  }
}

.l-footerCopyWrap {
  text-align: center;
  font-size: 1rem;
  color: $white;
  height: 29px;
  line-height: 29px;
  padding: 0 10px;
  background: $prj-brown01;
  font-family: $font-type02;
  // font-weight: $weight-thin;
  @include media-queries-up(md) {
    height: 35px;
    line-height: 35px;
    font-size: 1.1rem;
  }
}
