/* grid
========================================================== */
.c-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 5px;
  padding-left: 5px;
}

.c-containerRow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  &--center {
    justify-content: center;
    .c-col, [class*="c-md-col-"], [class*="c-col-"] {
      display: flex;
      width: auto;
    }
  }
}

.c-container--noGutters {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
  .c-containerRow {
    margin-right: 0;
    margin-left: 0;
  }
  .c-col,
  [class*="c-md-col-"],
  [class*="c-col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.c-col-flex {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.c-col-1,
.c-col-2,
.c-col-3,
.c-col-4,
.c-col-5,
.c-col-6,
.c-col-7,
.c-col-8,
.c-col-9,
.c-col-10,
.c-col-11,
.c-col-12,
.c-col,
.c-col-auto,
.c-md-col-1,
.c-md-col-2,
.c-md-col-3,
.c-md-col-4,
.c-md-col-5,
.c-md-col-6,
.c-md-col-7,
.c-md-col-8,
.c-md-col-9,
.c-md-col-10,
.c-md-col-11,
.c-md-col-12,
.c-md-col,
.c-md-col-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}

.c-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.c-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.c-col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.c-col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.c-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.c-col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.c-col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.c-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.c-col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.c-col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.c-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.c-col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.c-col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.c-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.c-order-first {
  order: -1;
}

.c-order-1 {
  order: 1;
}

.c-order-2 {
  order: 2;
}

.c-order-3 {
  order: 3;
}

.c-order-4 {
  order: 4;
}

.c-order-5 {
  order: 5;
}

.c-order-6 {
  order: 6;
}

.c-order-7 {
  order: 7;
}

.c-order-8 {
  order: 8;
}

.c-order-9 {
  order: 9;
}

.c-order-10 {
  order: 10;
}

.c-order-11 {
  order: 11;
}

.c-order-12 {
  order: 12;
}

.c-offset-1 {
  margin-left: 8.333333%;
}

.c-offset-2 {
  margin-left: 16.666667%;
}

.c-offset-3 {
  margin-left: 25%;
}

.c-offset-4 {
  margin-left: 33.333333%;
}

.c-offset-5 {
  margin-left: 41.666667%;
}

.c-offset-6 {
  margin-left: 50%;
}

.c-offset-7 {
  margin-left: 58.333333%;
}

.c-offset-8 {
  margin-left: 66.666667%;
}

.c-offset-9 {
  margin-left: 75%;
}

.c-offset-10 {
  margin-left: 83.333333%;
}

.c-offset-11 {
  margin-left: 91.666667%;
}

@include media-queries-only-down(md) {
  .c-sm-container--noGutters {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    .c-containerRow {
      margin-right: 0;
      margin-left: 0;
    }
    .c-col, [class*="c-md-col-"], [class*="c-col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@include media-queries-up(md) {
  .c-container {
    padding-right: 10px;
    padding-left: 10px;
  }
  .c-containerRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .c-col-1,
  .c-col-2,
  .c-col-3,
  .c-col-4,
  .c-col-5,
  .c-col-6,
  .c-col-7,
  .c-col-8,
  .c-col-9,
  .c-col-10,
  .c-col-11,
  .c-col-12,
  .c-col,
  .c-col-auto,
  .c-md-col-1,
  .c-md-col-2,
  .c-md-col-3,
  .c-md-col-4,
  .c-md-col-5,
  .c-md-col-6,
  .c-md-col-7,
  .c-md-col-8,
  .c-md-col-9,
  .c-md-col-10,
  .c-md-col-11,
  .c-md-col-12,
  .c-md-col,
  .c-md-col-auto {
    padding-right: 10px;
    padding-left: 10px;
  }
  .c-md-col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .c-md-col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .c-md-col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .c-md-col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .c-md-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .c-md-col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .c-md-col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .c-md-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .c-md-col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .c-md-col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .c-md-col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .c-md-col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .c-md-col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .c-md-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .c-md-order-first {
    order: -1;
  }
  .c-md-order-1 {
    order: 1;
  }
  .c-md-order-2 {
    order: 2;
  }
  .c-md-order-3 {
    order: 3;
  }
  .c-md-order-4 {
    order: 4;
  }
  .c-md-order-5 {
    order: 5;
  }
  .c-md-order-6 {
    order: 6;
  }
  .c-md-order-7 {
    order: 7;
  }
  .c-md-order-8 {
    order: 8;
  }
  .c-md-order-9 {
    order: 9;
  }
  .c-md-order-10 {
    order: 10;
  }
  .c-md-order-11 {
    order: 11;
  }
  .c-md-order-12 {
    order: 12;
  }
  .c-md-offset-0 {
    margin-left: 0;
  }
  .c-md-offset-1 {
    margin-left: 8.333333%;
  }
  .c-md-offset-2 {
    margin-left: 16.666667%;
  }
  .c-md-offset-3 {
    margin-left: 25%;
  }
  .c-md-offset-4 {
    margin-left: 33.333333%;
  }
  .c-md-offset-5 {
    margin-left: 41.666667%;
  }
  .c-md-offset-6 {
    margin-left: 50%;
  }
  .c-md-offset-7 {
    margin-left: 58.333333%;
  }
  .c-md-offset-8 {
    margin-left: 66.666667%;
  }
  .c-md-offset-9 {
    margin-left: 75%;
  }
  .c-md-offset-10 {
    margin-left: 83.333333%;
  }
  .c-md-offset-11 {
    margin-left: 91.666667%;
  }
}