/* icon
========================================================== */
[class^="c-icon-"]::before ,
[class*=" c-icon-"]::before {
  font-family: 'watabe-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.c-icon-overseasResort:before {
  content: "\e912";
}

.c-icon-domesticResort:before {
  content: "\e90a";
}

.c-icon-camera:before {
  content: "\e906";
}

.c-icon-dress:before {
  content: "\e90b";
}

.c-icon-ceremonialPlace:before {
  content: "\e907";
}

.c-icon-party:before {
  content: "\e921";
}

.c-icon-movie:before {
  content: "\e920";
}

.c-icon-anniversary:before {
  content: "\e91f";
}

.c-icon-tel-02:before {
  content: "\e91b";
}

.c-icon-tel:before {
  content: "\e915";
}

.c-icon-calendar:before {
  content: "\e905";
}

.c-icon-calendar-02:before {
  content: "\e922";
}

.c-icon-document:before {
  content: "\e909";
}

.c-icon-mail:before {
  content: "\e910";
}

.c-icon-present:before {
  content: "\e91c";
}

.c-icon-shop:before {
  content: "\e913";
}

.c-icon-talk:before {
  content: "\e914";
}

.c-icon-close:before {
  content: "\e918";
}

.c-icon-close-02:before {
  content: "\e91d";
}

.c-icon-list:before {
  content: "\e90f";
}

.c-icon-link:before {
  content: "\e90e";
}

.c-icon-insta-02:before {
  content: "\e91e";
}

.c-icon-insta:before {
  content: "\e917";
}

.c-icon-facebook:before {
  content: "\e90c";
}

.c-icon-twitter:before {
  content: "\ea96";
}

.c-icon-list-02:before {
  content: "\e901";
}

.c-icon-menu:before {
  content: "\e911";
}

.c-icon-pin:before {
  content: "\e908";
}

.c-icon-ceremonial-place-02:before {
  content: "\e916";
}

.c-icon-checkCircle:before {
  content: "\e91a";
}

.c-icon-plus:before {
  content: "\e90d";
}

.c-icon-check:before {
  content: "\e900";
}

.c-icon-info:before {
  content: "\e919";
}

.c-icon-arrow-r-02:before {
  content: "\e904";
}

.c-icon-arrowLeft:before {
  content: "\e902";
}

.c-icon-arrowRight:before {
  content: "\e903";
}
