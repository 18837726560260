/* forms
========================================================== */
.p-selectBoxWrap {
  font-size: 0;
  margin-left: -2px;
  margin-right: -2px;
  @include media-queries-up(md) {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.p-selectBox {
  display: inline-block;
  background: $white;
  border-radius: 3px;
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.2;
  margin: 2px;
  min-width: 80px;
  padding: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  &.is-selectBox-active {
    border-color: $prj-pink03;
    color: $prj-pink03;
  }
  @include media-queries-up(md) {
    border-radius: 6px;
    margin: 5px 10px;
    font-size: 1.4rem;
    min-width: 160px;
    padding: 10px;
    border-width: 2px;
  }
}