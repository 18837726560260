// -----------------------------------------------------------------
// flex
// ----------------------------------------------------------------
.u-flex-first { order: -1 !important; }
.u-flex-last { order: 1 !important; }
.u-flex-unordered { order: 0 !important; }
.u-flex-row { flex-direction: row !important; }
.u-flex-column { flex-direction: column !important; }
.u-flex-row-reverse { flex-direction: row-reverse !important; }
.u-flex-column-reverse { flex-direction: column-reverse !important; }
.u-flex-wrap { flex-wrap: wrap !important; }
.u-flex-nowrap { flex-wrap: nowrap !important; }
.u-flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.u-justify-content-start { justify-content: flex-start !important; }
.u-justify-content-end { justify-content: flex-end !important; }
.u-justify-content-center { justify-content: center !important; }
.u-justify-content-between { justify-content: space-between !important ;}
.u-justify-content-around { justify-content: space-around !important; }
.u-align-items-start { align-items: flex-start !important; }
.u-align-items-end { align-items: flex-end !important; }
.u-align-items-center { align-items: center !important; }
.u-align-items-baseline { align-items: baseline !important; }
.u-align-items-stretch { align-items: stretch !important; }
.u-align-content-start { align-content: flex-start !important; }
.u-align-content-end { align-content: flex-end !important; }
.u-align-content-center { align-content: center !important; }
.u-align-content-between { align-content: space-between !important; }
.u-align-content-around { align-content: space-around !important; }
.u-align-content-stretch { align-content: stretch !important; }
.u-align-self-auto { align-self: auto !important; }
.u-align-self-start { align-self: flex-start !important; }
.u-align-self-end { align-self: flex-end !important; }
.u-align-self-center { align-self: center !important; }
.u-align-self-baseline { align-self: baseline !important; }
.u-align-self-stretch { align-self: stretch !important; }

@include media-queries-only-down(md) {
  .u-sm-flex-first { order: -1 !important; }
  .u-sm-flex-last { order: 1 !important; }
  .u-sm-flex-unordered { order: 0 !important; }
  .u-sm-flex-row { flex-direction: row !important; }
  .u-sm-flex-column { flex-direction: column !important; }
  .u-sm-flex-row-reverse { flex-direction: row-reverse !important; }
  .u-sm-flex-column-reverse { flex-direction: column-reverse !important; }
  .u-sm-flex-wrap { flex-wrap: wrap !important; }
  .u-sm-flex-nowrap { flex-wrap: nowrap !important; }
  .u-sm-flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .u-sm-justify-content-start { justify-content: flex-start !important; }
  .u-sm-justify-content-end { justify-content: flex-end !important; }
  .u-sm-justify-content-center { justify-content: center !important; }
  .u-sm-justify-content-between { justify-content: space-between !important ;}
  .u-sm-justify-content-around { justify-content: space-around !important; }
  .u-sm-align-items-start { align-items: flex-start !important; }
  .u-sm-align-items-end { align-items: flex-end !important; }
  .u-sm-align-items-center { align-items: center !important; }
  .u-sm-align-items-baseline { align-items: baseline !important; }
  .u-sm-align-items-stretch { align-items: stretch !important; }
  .u-sm-align-content-start { align-content: flex-start !important; }
  .u-sm-align-content-end { align-content: flex-end !important; }
  .u-sm-align-content-center { align-content: center !important; }
  .u-sm-align-content-between { align-content: space-between !important; }
  .u-sm-align-content-around { align-content: space-around !important; }
  .u-sm-align-content-stretch { align-content: stretch !important; }
  .u-sm-align-self-auto { align-self: auto !important; }
  .u-sm-align-self-start { align-self: flex-start !important; }
  .u-sm-align-self-end { align-self: flex-end !important; }
  .u-sm-align-self-center { align-self: center !important; }
  .u-sm-align-self-baseline { align-self: baseline !important; }
  .u-sm-align-self-stretch { align-self: stretch !important; }
}

@include media-queries-up(md) {
  .u-md-flex-first { order: -1 !important; }
  .u-md-flex-last { order: 1 !important; }
  .u-md-flex-unordered { order: 0 !important; }
  .u-md-flex-row { flex-direction: row !important; }
  .u-md-flex-column { flex-direction: column !important; }
  .u-md-flex-row-reverse { flex-direction: row-reverse !important; }
  .u-md-flex-column-reverse { flex-direction: column-reverse !important; }
  .u-md-flex-wrap { flex-wrap: wrap !important; }
  .u-md-flex-nowrap { flex-wrap: nowrap !important; }
  .u-md-flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .u-md-justify-content-start { justify-content: flex-start !important; }
  .u-md-justify-content-end { justify-content: flex-end !important; }
  .u-md-justify-content-center { justify-content: center !important; }
  .u-md-justify-content-between { justify-content: space-between !important ;}
  .u-md-justify-content-around { justify-content: space-around !important; }
  .u-md-align-items-start { align-items: flex-start !important; }
  .u-md-align-items-end { align-items: flex-end !important; }
  .u-md-align-items-center { align-items: center !important; }
  .u-md-align-items-baseline { align-items: baseline !important; }
  .u-md-align-items-stretch { align-items: stretch !important; }
  .u-md-align-content-start { align-content: flex-start !important; }
  .u-md-align-content-end { align-content: flex-end !important; }
  .u-md-align-content-center { align-content: center !important; }
  .u-md-align-content-between { align-content: space-between !important; }
  .u-md-align-content-around { align-content: space-around !important; }
  .u-md-align-content-stretch { align-content: stretch !important; }
  .u-md-align-self-auto { align-self: auto !important; }
  .u-md-align-self-start { align-self: flex-start !important; }
  .u-md-align-self-end { align-self: flex-end !important; }
  .u-md-align-self-center { align-self: center !important; }
  .u-md-align-self-baseline { align-self: baseline !important; }
  .u-md-align-self-stretch { align-self: stretch !important; }
}