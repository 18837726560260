/* area-tile
========================================================== */
.p-areaTile {
  &__inner {
    position: relative;
  }
  &__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  &__info {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px 10px;
    @include media-queries-up(md) {
      padding: 20px 25px;
    }
    &--xSmall {
      padding: 5px 10px;
      @include media-queries-up(md) {
        padding: 20px 25px;
      }
    }
    &--small {
      padding: 15px 10px;
      @include media-queries-up(md) {
        padding: 10px 15px;
      }
    }
  }
  &__txtArea {
    font-size: 1.2rem;
    @include media-queries-up(md) {
      font-size: 1.6rem;
    }
  }
  &__name {
    display: inline-block;
    margin-right: .3em;
    font-size: 2.2rem;
    font-family: $font-type02;
    font-weight: $weight-semiBold;
    line-height: 1.4;
    letter-spacing: .06em;
    @include media-queries-up(md) {
      font-size: 3.4rem;
    }
    &--large {
      font-size: 2.2rem;
      @include media-queries-up(md) {
        font-size: 4.4rem;
        line-height: 1.1;
      }
    }
    &--small {
      display: block;
      margin-right: 0;
      font-size: 2.2rem;
      @include media-queries-up(md) {
        font-size: 2.4rem;
      }
    }
    &--xSmall {
      display: block;
      margin-right: 0;
      margin-bottom: .2em;
      font-size: 1.3rem;
      @include media-queries-up(md) {
        font-size: 1.6rem;
      }
    }
  }
  &__subName {
    display: inline-block;
    font-size: 1.2rem;
    font-family: $font-type03;
    font-weight: $weight-bold;
    @include media-queries-up(md) {
      font-size: 1.6rem;
    }
    &--small {
      display: block;
      @include media-queries-up(md) {
        font-size: 1.2rem;
      }
    }
    &--large {
      font-size: 1.2rem;
      @include media-queries-up(md) {
        font-size: 2.4rem;
      }
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    flex-flow: column;
    font-size: 1rem;
    margin-left: .5em;
    white-space: nowrap;
    text-align: center;
    @include media-queries-up(md) {
      margin-left: 1em;
      font-size: 1.2rem;
    }
    &--small {
      font-size: 1rem;
      @include media-queries-up(md) {
        font-size: 1rem;
      }
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 21px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: $prj-pink03;
    margin-bottom: 5px;
    font-size: 1.2rem;
    @include media-queries-up(md) {
      min-width: 30px;
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
      font-size: 1.6rem;
    }
    &--small {
      margin-bottom: 5px;
      @include media-queries-up(md) {
        margin-bottom: 5px;
      }
    }
  }
  &__btnTxt {
    line-height: 1.2;
  }
  a {
    color: $white;
    display: block;
    img {
      transition: .3s ease-out;
    }
    &:hover {
      opacity: 1;
      @include media-queries-up(md) {
        .p-areaTile__photo img {
          transform: scale(1.02);
          transition: .3s ease-out;
        }
      }
    }
  }
}