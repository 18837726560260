/* tab
========================================================== */
.p-tabWrap {
  position: relative;
}

.p-tabNavi {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid $prj-pink03;
  padding: 0 10px;
  margin-bottom: 20px;
  @include media-queries-up(md) {
    border-width: 4px;
    padding: 0 20px;
    margin-bottom: 40px;
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background: $prj-pink08;
    font-size: 1.5rem;
    padding: 10px 10px;
    cursor: pointer;
    margin-right: 20px;
    font-family: $font-type04;
    font-weight: $weight-regular;
    @include media-queries-up(md) {
      padding: 5px 10px;
      font-size: 2rem;
      margin-right: 55px;
      height: 40px;
      letter-spacing: .1em;
    }
    &--small {
      margin-right: 10px;
      @include media-queries-up(md) {
        margin-right: 45px;
      }
    }
    &.is-active {
      padding: 15px 10px;
      background: $prj-pink03;
      @include media-queries-up(md) {
        padding: 5px 10px;
        height: 80px;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.p-tabContWrap {
  position: relative;
}

.p-tabCont {
  display: none;
  &.is-active {
    display: block;
  }
}

.p-tabContInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media-queries-up(md) {
    flex-direction: row;
  }
}


.p-tabCont__item {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px solid $prj-gray01;
  width: 100%;
  @include media-queries-up(md) {
    padding: 15px;
    margin-bottom: 0;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.p-tabCont__itemCardWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
  @include media-queries-up(md) {
      margin-bottom: -20px;
  }
}

.p-tabCont__itemCard {
  width: 100%;
  margin-bottom: 30px;
  @include media-queries-up(md) {
    width: calc(50% - 10px);
    margin-bottom: 20px;
    margin-right: 20px;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

// plan
.p-planTabCont {
  font-size: 1.3rem;
  @include media-queries-up(md) {
    font-size: 1.6rem;
  }
  &__head {
    text-align: center;
    border-bottom: 1px solid $prj-gray01;
    padding: 10px 10px 15px;
    margin-bottom: 15px;
    @include media-queries-up(md) {
      padding: 20px 10px 25px;
      margin-bottom: 20px;
    }
  }
}

.p-planTabContTitle {
  text-align: center;
  font-size: 1.8rem;
  color: $prj-gray07;
  margin-bottom: 20px;
  &__subTxt {
    display: block;
    font-size: 1.1rem;
    font-family: $font-type02;
    font-weight: $weight-demiLight;
    margin-bottom: .1em;
  }
  @include media-queries-up(md) {
    font-size: 2.4rem;
    &__subTxt {
      font-size: 1.8rem;
    }
  }
}

.p-planTabContPrice {
  color: $prj-pink02;
  font-size: 3rem;
  margin-bottom: 15px;
  &__sign {
    color: $prj-gray07;
    font-size: 1.3rem;
    margin-right: .2em;
  }
  @include media-queries-up(md) {
    font-size: 4.1rem;
    margin-bottom: 20px;
    &__sign {
      font-size: 2.4rem;
    }
  }
}

.p-planTabContHeadTxt {
  font-size: 1.2rem;
  @include media-queries-up(md) {
    font-size: 1.8rem;
  }
}

.p-planTabContDetail {
  @include media-queries-up(md) {
    padding: 0 10px 20px;
  }
}

.p-planIconBlock {
  display: block;
  text-align: center;
  margin: 15px auto;
  color: $prj-pink03;
  font-size: 1.2rem;
  @include media-queries-up(md) {
    font-size: 2.4rem;
  }
}