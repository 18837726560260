// -----------------------------------------------------------------
// float
// -----------------------------------------------------------------
.u-float-left { float: left !important; }
.u-float-right { float: right !important; }
.u-float-none { float: none !important; }

@include media-queries-only-down(md) {
  .u-sm-float-left { float: left !important; }
  .u-sm-float-right { float: right !important; }
  .u-sm-float-none { float: none !important; }
}

@include media-queries-up(md) {
  .u-md-float-left { float: left !important; }
  .u-md-float-right { float: right !important; }
  .u-md-float-none { float: none !important; }
}