/* label
========================================================== */
@mixin label {
  // display: inline-block;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  padding: 3px 5px;
  font-size: 1.2rem;
  margin: 3px 3px 3px 0;
  border: solid 1px transparent;
  a {
    color: $prj-gray02;
  }
  @include media-queries-up(md) {
    border-width: 2px;
    font-size: 1.2rem;
  }
  &--size {
    &-m {
      padding: 2px 10px;
    }
    &-l {
      @include media-queries-up(md) {
        padding: 8px 15px;
      }
    }
  }
  &--radius {
    border-radius: 3px;
  }
  &[class^="c-icon-"]::before,
  &[class*=" c-icon-"]::before {
    margin-right: .5em;
  }
}

.c-labelWrap {
  margin-bottom: -5px;
  .c-label {
    margin: 0 5px 5px 0;
  }
  @include media-queries-up(md) {
    border-width: 2px;
    font-size: 1.2rem;
    margin-bottom: -15px;
    .c-label {
      margin: 0 15px 15px 0;
    }
  }
}

.c-label {
  @include label;
}

.c-label--lineGray {
  color: $prj-gray02;
  border-color: $prj-gray02;
  a {
    color: $prj-gray02;
  }
}

.c-label--brown {
  color: $prj-brown01;
  border-color: $prj-brown01;
  a {
    color: $prj-brown01;
  }
}

.c-label--lineBrown {
  color: $prj-brown01;
  border-color: $prj-brown01;
  a {
    color: $prj-brown01;
  }
}

.c-label--lineBrownDark {
  color: $prj-brown02;
  border-color: $prj-brown02;
  a {
    color: $prj-brown02;
  }
}

.c-label--linePink {
  color: $prj-pink03;
  border-color: $prj-pink03;
  a {
    color: $prj-pink03;
  }
}

.c-label--pink {
  background: $prj-pink03;
  color: $white;
  a {
    color: $white;
  }
}