/* progress
========================================================== */
.c-progress {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  &__circle {
    background-color: $prj-gray09;
    border-radius: 50%;
    min-width: 45px;
    width: 45px;
    height: 45px;
    color: $prj-gray13;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.6rem;
  }
  &__item {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    width: 50%;
    &:after {
      width: 100%;
      height: 3px;
      content: '';
      position: absolute;
      background-color: $prj-gray09;
      top: 22px;
      left: -50%;
      z-index: -1;
    }
    &:first-child:after {
      content: none;
    }
    &.is-active {
      .c-progress__circle {
        background-color: $prj-pink03;
        color: $white;
      }
      .c-progress__txt {
        color: $prj-pink03;
      }
      &:after {
        background-color: $prj-pink03;
      }
    }
  }
}
