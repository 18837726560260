// -----------------------------------------------------------------
// text
// -----------------------------------------------------------------
//family
// ---------------------------------------------
.u-ff-default { font-family: $font-default !important; }
.u-ff-type02 { font-family: $font-type02 !important; } // Montserrat
.u-ff-type03 { font-family: $font-type03 !important; } // Noto Sans Japanese
.u-ff-type04 { font-family: $font-type04 !important; } // Lato

// font style
// ---------------------------------------------
.u-font-italic { font-style: italic !important; }

// font weigh
// ---------------------------------------------
.u-txt-normal { font-weight: normal !important; }
.u-txt-bold { font-weight: bold !important; }

.u-weight-demiLight { font-weight: $weight-demiLight !important; } // 300 Montserrat-light
.u-weight-regular { font-weight: $weight-regular !important; } // 400 Montserrat-regular Lat-regular
.u-weight-medium { font-weight: $weight-medium !important; } // 500 Montserrat-medium
.u-weight-bold { font-weight: $weight-bold !important; } // 700 Lat-bold

// text align
// ---------------------------------------------
.u-txt-justify { text-align: justify !important; }
.u-txt-center { text-align: center !important; }
.u-txt-left { text-align: left !important; }
.u-txt-right { text-align: right !important; }

@include media-queries-only-down(md) {
  .u-sm-txt-justify { text-align: justify !important; }
  .u-sm-txt-center { text-align: center !important; }
  .u-sm-txt-left { text-align: left !important; }
  .u-sm-txt-right { text-align: right !important; }
}
@include media-queries-up(md) {
  .u-md-txt-justify { text-align: justify !important; }
  .u-md-txt-center { text-align: center !important; }
  .u-md-txt-left { text-align: left !important; }
  .u-md-txt-right { text-align: right !important; }
}


// white-space
// ---------------------------------------------
.u-txt-nowrap { white-space: nowrap !important; }

// txt-hiden
// ---------------------------------------------
// .u-txt-hide { @include text-hide();}

//color
// ---------------------------------------------
.u-txt-white { color: $white !important; } // #fff
.u-txt-pink01 { color: $prj-pink01 !important; } // #eb6877
.u-txt-pink02 { color: $prj-pink02 !important; } // #ff8b8b
.u-txt-pink03 { color: $prj-pink03 !important; } // #ffa8a8
.u-txt-gray04 {color: $prj-gray04 !important; } // #7c7c7c
.u-txt-gray07 { color: $prj-gray07 !important; } // #666
.u-txt-gray08 { color: $prj-gray08 !important; } // #626262
.u-txt-gray13 {color: $prj-gray13 !important; } // #535353

// line-hight
// ---------------------------------------------
.u-lh1 { line-height: 1 !important; }
.u-lh11 { line-height: 1.1 !important; }
.u-lh12 { line-height: 1.2 !important; }
.u-lh13 { line-height: 1.3 !important; }
.u-lh14 { line-height: 1.4 !important; }
.u-lh15 { line-height: 1.5 !important; }
.u-lh16 { line-height: 1.6 !important; }
.u-lh17 { line-height: 1.7 !important; }
.u-lh18 { line-height: 1.8 !important; }
.u-lh19 { line-height: 1.9 !important; }
.u-lh2 { line-height: 2 !important; }
.u-lh21 { line-height: 2.1 !important; }
.u-lh22 { line-height: 2.2 !important; }
.u-lh23 { line-height: 2.3 !important; }
.u-lh24 { line-height: 2.4 !important; }
.u-lh25 { line-height: 2.5 !important; }
.u-lh26 { line-height: 2.6 !important; }
.u-lh27 { line-height: 2.7 !important; }
.u-lh28 { line-height: 2.8 !important; }

@include media-queries-only-down(md) {
  .u-sm-lh1 { line-height: 1 !important; }
  .u-sm-lh11 { line-height: 1.1 !important; }
  .u-sm-lh12 { line-height: 1.2 !important; }
  .u-sm-lh13 { line-height: 1.3 !important; }
  .u-sm-lh14 { line-height: 1.4 !important; }
  .u-sm-lh15 { line-height: 1.5 !important; }
  .u-sm-lh16 { line-height: 1.6 !important; }
  .u-sm-lh17 { line-height: 1.7 !important; }
  .u-sm-lh18 { line-height: 1.8 !important; }
  .u-sm-lh19 { line-height: 1.9 !important; }
  .u-sm-lh2 { line-height: 2 !important; }
  .u-sm-lh21 { line-height: 2.1 !important; }
  .u-sm-lh22 { line-height: 2.2 !important; }
  .u-sm-lh23 { line-height: 2.3 !important; }
  .u-sm-lh24 { line-height: 2.4 !important; }
  .u-sm-lh25 { line-height: 2.5 !important; }
  .u-sm-lh26 { line-height: 2.6 !important; }
  .u-sm-lh27 { line-height: 2.7 !important; }
  .u-sm-lh28 { line-height: 2.8 !important; }
}

@include media-queries-up(md) {
  .u-md-lh1 { line-height: 1 !important; }
  .u-md-lh11 { line-height: 1.1 !important; }
  .u-md-lh12 { line-height: 1.2 !important; }
  .u-md-lh13 { line-height: 1.3 !important; }
  .u-md-lh14 { line-height: 1.4 !important; }
  .u-md-lh15 { line-height: 1.5 !important; }
  .u-md-lh16 { line-height: 1.6 !important; }
  .u-md-lh17 { line-height: 1.7 !important; }
  .u-md-lh18 { line-height: 1.8 !important; }
  .u-md-lh19 { line-height: 1.9 !important; }
  .u-md-lh2 { line-height: 2 !important; }
  .u-md-lh21 { line-height: 2.1 !important; }
  .u-md-lh22 { line-height: 2.2 !important; }
  .u-md-lh23 { line-height: 2.3 !important; }
  .u-md-lh24 { line-height: 2.4 !important; }
  .u-md-lh25 { line-height: 2.5 !important; }
  .u-md-lh26 { line-height: 2.6 !important; }
  .u-md-lh27 { line-height: 2.7 !important; }
  .u-md-lh28 { line-height: 2.8 !important; }
}

//font-size
// ---------------------------------------------
.u-fz1 { font-size: 1rem !important}
.u-fz11 { font-size: 1.1rem !important}
.u-fz12 { font-size: 1.2rem !important}
.u-fz13 { font-size: 1.3rem !important}
.u-fz14 { font-size: 1.4rem !important}
.u-fz15 { font-size: 1.5rem !important}
.u-fz16 { font-size: 1.6rem !important}
.u-fz17 { font-size: 1.7rem !important}
.u-fz18 { font-size: 1.8rem !important}
.u-fz19 { font-size: 1.9rem !important}
.u-fz2 { font-size: 2rem !important}
.u-fz21 { font-size: 2.1rem !important}
.u-fz22 { font-size: 2.2rem !important}
.u-fz23 { font-size: 2.3rem !important}
.u-fz24 { font-size: 2.4rem !important}
.u-fz25 { font-size: 2.5rem !important}
.u-fz26 { font-size: 2.6rem !important}
.u-fz27 { font-size: 2.7rem !important}
.u-fz28 { font-size: 2.8rem !important}
.u-fz29 { font-size: 2.9rem !important}
.u-fz3 { font-size: 3rem !important}
.u-fz31 { font-size: 3.1rem !important}
.u-fz32 { font-size: 3.2rem !important}
.u-fz33 { font-size: 3.3rem !important}
.u-fz34 { font-size: 3.4rem !important}
.u-fz35 { font-size: 3.5rem !important}
.u-fz36 { font-size: 3.6rem !important}
.u-fz37 { font-size: 3.7rem !important}
.u-fz38 { font-size: 3.8rem !important}
.u-fz39 { font-size: 3.9rem !important}
.u-fz4 { font-size: 4rem !important}

@include media-queries-only-down(md) {
  .u-sm-fz1 { font-size: 1rem !important}
  .u-sm-fz11 { font-size: 1.1rem !important}
  .u-sm-fz12 { font-size: 1.2rem !important}
  .u-sm-fz13 { font-size: 1.3rem !important}
  .u-sm-fz14 { font-size: 1.4rem !important}
  .u-sm-fz15 { font-size: 1.5rem !important}
  .u-sm-fz16 { font-size: 1.6rem !important}
  .u-sm-fz17 { font-size: 1.7rem !important}
  .u-sm-fz18 { font-size: 1.8rem !important}
  .u-sm-fz19 { font-size: 1.9rem !important}
  .u-sm-fz2 { font-size: 2rem !important}
  .u-sm-fz21 { font-size: 2.1rem !important}
  .u-sm-fz22 { font-size: 2.2rem !important}
  .u-sm-fz23 { font-size: 2.3rem !important}
  .u-sm-fz24 { font-size: 2.4rem !important}
  .u-sm-fz25 { font-size: 2.5rem !important}
  .u-sm-fz26 { font-size: 2.6rem !important}
  .u-sm-fz27 { font-size: 2.7rem !important}
  .u-sm-fz28 { font-size: 2.8rem !important}
  .u-sm-fz29 { font-size: 2.9rem !important}
  .u-sm-fz3 { font-size: 3rem !important}
  .u-sm-fz31 { font-size: 3.1rem !important}
  .u-sm-fz32 { font-size: 3.2rem !important}
  .u-sm-fz33 { font-size: 3.3rem !important}
  .u-sm-fz34 { font-size: 3.4rem !important}
  .u-sm-fz35 { font-size: 3.5rem !important}
  .u-sm-fz36 { font-size: 3.6rem !important}
  .u-sm-fz37 { font-size: 3.7rem !important}
  .u-sm-fz38 { font-size: 3.8rem !important}
  .u-sm-fz39 { font-size: 3.9rem !important}
  .u-sm-fz4 { font-size: 4rem !important}
}

@include media-queries-up(md) {
  .u-md-fz1 { font-size: 1rem !important}
  .u-md-fz11 { font-size: 1.1rem !important}
  .u-md-fz12 { font-size: 1.2rem !important}
  .u-md-fz13 { font-size: 1.3rem !important}
  .u-md-fz14 { font-size: 1.4rem !important}
  .u-md-fz15 { font-size: 1.5rem !important}
  .u-md-fz16 { font-size: 1.6rem !important}
  .u-md-fz17 { font-size: 1.7rem !important}
  .u-md-fz18 { font-size: 1.8rem !important}
  .u-md-fz19 { font-size: 1.9rem !important}
  .u-md-fz2 { font-size: 2rem !important}
  .u-md-fz21 { font-size: 2.1rem !important}
  .u-md-fz22 { font-size: 2.2rem !important}
  .u-md-fz23 { font-size: 2.3rem !important}
  .u-md-fz24 { font-size: 2.4rem !important}
  .u-md-fz25 { font-size: 2.5rem !important}
  .u-md-fz26 { font-size: 2.6rem !important}
  .u-md-fz27 { font-size: 2.7rem !important}
  .u-md-fz28 { font-size: 2.8rem !important}
  .u-md-fz29 { font-size: 2.9rem !important}
  .u-md-fz3 { font-size: 3rem !important}
  .u-md-fz31 { font-size: 3.1rem !important}
  .u-md-fz32 { font-size: 3.2rem !important}
  .u-md-fz33 { font-size: 3.3rem !important}
  .u-md-fz34 { font-size: 3.4rem !important}
  .u-md-fz35 { font-size: 3.5rem !important}
  .u-md-fz36 { font-size: 3.6rem !important}
  .u-md-fz37 { font-size: 3.7rem !important}
  .u-md-fz38 { font-size: 3.8rem !important}
  .u-md-fz39 { font-size: 3.9rem !important}
  .u-md-fz4 { font-size: 4rem !important}
}