// -----------------------------------------------------------------
// background
// -----------------------------------------------------------------
.u-bg-white { background-color: $white !important; }
.u-bg-black { background-color: $black !important; }
.u-bg-pink05 { background-color: $prj-pink05 !important; } //#f5eced
.u-bg-gray06 { background-color: $prj-gray06 !important; } //#f1f1f1
.u-bg-gray09 { background-color: $prj-gray09 !important; } //#eee
.u-bg-gray15 { background-color: $prj-gray15 !important; }//#f6f6f6

@include media-queries-only-down(md) {
  .u-sm-bg-white { background-color: $white !important; }
  .u-sm-bg-black { background-color: $black !important; }
  .u-sm-bg-pink05 { background-color: $prj-pink05 !important; } //#f5eced
  .u-sm-bg-gray06 { background-color: $prj-gray06 !important; } //#f1f1f1
  .u-sm-bg-gray09 { background-color: $prj-gray09 !important; } //#eee
  .u-sm-bg-gray15 { background-color: $prj-gray15 !important; }//#f6f6f6
}

@include media-queries-up(md) {
  .u-md-bg-white { background-color: $white !important; }
  .u-md-bg-black { background-color: $black !important; }
  .u-md-bg-pink05 { background-color: $prj-pink05 !important; } //#f5eced
  .u-md-bg-gray06 { background-color: $prj-gray06 !important; } //#f1f1f1
  .u-md-bg-gray09 { background-color: $prj-gray09 !important; } //#eee
  .u-md-bg-gray15 { background-color: $prj-gray15 !important; }//#f6f6f6
}