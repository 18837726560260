/* subnavi
========================================================== */

.l-gnavi {
  position: relative;
  width: 100%;
  background: $prj-pink03;
  display: none;
  &--wedding {
    display: block;
  }
  @include media-queries-up(md) {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
  }
}

.l-gnavi__frame {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  height: 44px;
  @include media-queries-up(md) {
    height: 40px;
  }
}

.l-gnavi__title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 1.3rem;
  @include media-queries-up(md) {
    font-size: 1.6rem;
  }
}

.l-gnavi__list {
  display: none;
  @include media-queries-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.l-gnavi__listItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $white;
  margin: 0 25px;
  &--exLink {
    position: relative;
    &::after {
      @include icon-set;
      margin-left: .3em;
      content: "\e90e";
      display: inline-block;
    }
  }
  &.is-navihover {
    &::before {
      position: absolute;
      left: 50%;
      margin-left: -15px;
      bottom: 0;
      width: 30px;
      height: 2px;
      background: $white;
      content: "";
    }
  }
  &.is-active {
    &::before {
      position: absolute;
      left: 50%;
      margin-left: -15px;
      bottom: 0;
      width: 30px;
      height: 2px;
      background: $white;
      content: "";
    }
  }
  @include media-queries-up(md) {
    font-size: 1.4rem;
  }
  a {
    color: $white;
  }
}

.l-gnavi__guide {
  display: none;
  @include media-queries-up(md) {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.l-gnavi__guideItem {
  font-size: 1.2rem;
  margin-left: 25px;
  &:first-child {
    margin-left: 0;
  }
  a {
    color: $white;
  }
}

.l-gnavi__exList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: 1.3rem;
  @include media-queries-up(md) {
    display: none;
  }
}

.l-gnavi__exListItem {
  display: flex;
  align-items: center;
  height: 44px;
  color: $white;
  margin: 0 15px;
  white-space: nowrap;
  &--main {
    position: relative;
    margin-right: 0;
    margin-left: 0;
    .l-gnavi__exListItemInner {
      display: inline-block;
      padding-right: 15px;
      border-right: 1px solid $white;
    }
    &::after {
      position: absolute;
      left: 50%;
      margin-left: -15px;
      bottom: 0;
      width: 30px;
      height: 1px;
      background: $white;
      content: "";
    }
  }
  @include media-queries-up(md) {
    font-size: 1.4rem;
  }
}

.l-gnavi__exListItemInner {
  color: $white;
  [class^="c-icon-"]::before,
  [class*=" c-icon-"]::before {
    margin-right: .3em;
  }
}

.l-gnaviCnt {
  position: absolute;
  display: block;
  top: 40px;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0; // height: 150px;
  // opacity: 1;
  background: $prj-translucentWhite;
  z-index: $zindex-headerOnNavi;
  overflow: hidden;
  transition: all .4s cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

.l-gnaviCnt-active {
  opacity: 1;
  height: auto;
  transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.l-gnaviCntItemWrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
}

.l-gnaviCntItemMore {
  align-self: center;
  text-align: center;
}

.l-gnaviCntItemMore__icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: $prj-pink01;
  color: $white;
  margin-bottom: 5px;
}

.l-gnaviCntItemMore__txt {
  display: block;
  font-size: 1.1rem;
}

.l-gnaviCntItem {
  max-width: 120px;
  margin-right: 20px;
  text-align: center;
  font-family: $font-type03;
  font-weight: $weight-medium;
  &:last-child {
    margin-right: 0;
  }
  >a {
    display: block;
  }
}

.l-gnaviCntItem__photo {
  width: 100px;
  height: auto;
  margin: 0 auto 10px;
  >img {
    width: 100%;
  }
}

.l-gnaviCntItem__txt {
  line-height: 1.2;
}