/* button
========================================================== */
@mixin btn {
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 1.3rem;
  font-family: $font-type03;
  font-weight: $weight-medium;
  max-width: 100%;
  letter-spacing: .1em;
  @include media-queries-up(md) {
    font-size: 1.6rem;
  }
  &--size {
    &-m {
      min-width: 234px;
      max-width: 100%;
      @include media-queries-up(md) {
        min-width: 300px;
      }
    }
    &-l {
      min-width: 265px;
      max-width: 100%;
      @include media-queries-up(md) {
        min-width: 408px;
      }
    }
  }
  &--block {
    display: block;
    width: 100%;
  }
  &--radius {
    border-radius: 50px;
  }
  a {
    display: block;
    &:hover {
      opacity: 1;
    }
  }
}

.c-btn__icon {
  display: inline-flex;
  line-height: 1;
  vertical-align: bottom;
  font-size: 1.6rem;
  margin-right: 5px;
  @include media-queries-up(md) {
    font-size: 2.8rem;
    margin-right: 10px;
  }
}

.c-btn__inner {
  padding: 10px;
  @include media-queries-up(md) {
    padding: 15px;
  }
  &--large {
    padding: 15px 10px;
    font-size: 1.3rem;
    @include media-queries-up(md) {
      padding: 25px 20px;
      font-size: 1.8rem;
    }
  }
  &--inLarge {
    padding: 15px 10px;
    font-size: 1.3rem;
    @include media-queries-up(md) {
      padding: 20px 20px;
      font-size: 1.8rem;
    }
  }
}

.c-btn__badgeCircle {
  position: absolute;
  top: -23px;
  right: -10px;
  color: $prj-pink01;
  background: url(#{$img-common-path}bg-btn-badge-circle.png) no-repeat;
  background-size: contain;
  width: 47px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
  padding-bottom: 2px;
  @include media-queries-up(md) {
    padding-bottom: 5px;
    top: -49px;
    right: -10px;
    width: 79px;
    height: 85px;
    font-size: 1.8rem;
  }
}

.c-btn {
  @include btn;
}

.c-btn-linePink {
  border: 2px solid $prj-pink01;
  @include media-queries-up(md) {
    border: 4px solid $prj-pink01;
  }
  a {
    margin: -2px;
    @include media-queries-up(md) {
      margin: -4px;
    }
  }
}

.c-btn-linePink {
  border: 2px solid $prj-pink01;
  &:active {
    background: $prj-pink01;
    border-color: transparent;
    color: $white;
    a {
      color: $white;
    }
  }
  @include media-queries-up(md) {
    border-width: 4px;
    &:hover, &:active {
      border-color: transparent;
      background: $prj-pink01;
      color: $white;
      a {
        color: $white;
      }
    }
  }
  a {
    margin: -2px;
    @include media-queries-up(md) {
      margin: -4px;
    }
  }
}

.c-btn-pink {
  background: $prj-pink01;
  color: $white;
  border: 2px solid transparent;
  &:hover {
    background: $prj-pink07;
  }
  @include media-queries-up(md) {
    border: 4px solid transparent;
  }
  a {
    color: $white;
    margin: -2px;
    @include media-queries-up(md) {
      margin: -4px;
    }
  }
}

.c-btn-darkPink {
  background: $prj-pink01;
  color: $white;
  border: 2px solid transparent;
  @include media-queries-up(md) {
    border: 4px solid transparent;
  }
  a {
    color: $white;
    margin: -2px;
    @include media-queries-up(md) {
      margin: -4px;
    }
  }
}



.c-btnCircleWrap {
  display: flex;
  justify-content: center;
}
.c-btnCircle {
  min-width: 77px;
  width: 77px;
  height: 77px;
  background: $prj-pink01;
  border-radius: 50%;
  margin-left: 12px;
  margin-right: 12px;
  @include media-queries-up(md) {
    font-size: 1.6rem;
    width: 116px;
    height: 116px;
    margin-left: 14px;
    margin-right: 14px;
  }
  &--spaceLarge {
    @include media-queries-up(md) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
  &__icon {
    display: flex;
    font-size: 2rem;
    margin-bottom: 5px;
    @include media-queries-up(md) {
      font-size: 3.2rem;
      margin-bottom: 10px;
    }
  }
  &__txt {
    font-size: 1.1rem;
    @include media-queries-up(md) {
      font-size: 1.6rem;
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 100%;
    height: 100%;
    color: $white;
  }
}

.c-selectBtnWrap {
  font-size: 0;
  text-align: center;
  margin-bottom: -10px;
}

.c-selectBtn {
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 1.3rem;
  font-family: $font-type03;
  font-weight: $weight-medium;
  letter-spacing: .1em;
  line-height: 1.2;
  background: $white;
  border: 2px solid transparent;
  color: inherit;
  padding: 8px;
  min-width: 150px;
  max-width: 100%;
  margin: 0 5px 10px;
  border-radius: 3px;
  @include media-queries-up(md) {
    font-size: 1.4rem;
    padding: 15px 10px;
    min-width: 260px;
    margin: 0 10px 10px;
    border-radius: 6px;
  }
}

.c-selectBtn-pink {
  background: $prj-pink01;
  color: $white;
  border: 2px solid transparent;
  &:hover {
    background: $prj-pink07;
  }
  a {
    color: $white;
    margin: -2px;
  }
}

.c-selectBtn-white {
  background: $white;
  color: inherit;
  border: 2px solid transparent;
  // &:hover {
  // }
  a {
    color: inherit;
    margin: -2px;
  }
}