/* sort
========================================================== */
.p-sortTabWrap {

}

.p-sortNavi {
  text-align: center;
  margin-bottom: 30px;
}
.p-sortNavi__item {
  display: inline-block;
  font-size: 1.3rem;
  margin: 0 10px;
  cursor: pointer;
  &.is-active {
    color: $prj-pink02;
  }
  @include media-queries-up(md) {
    font-size: 1.6rem;
    margin: 0 40px;
  }
}
.p-sortNavi__icon {
  margin-right: .3em;
  font-size: 2.4rem;
}
.p-sortCont {
  display: none;
  &.is-active {
    display: block;
  }
}

.p-sortList {

}

.p-sortList__head {
  font-family: $font-type03;
  font-weight: $weight-medium;
  background: $prj-gray17;
  color: $white;
  text-align: center;
  font-size: 1.1rem;
  padding: 10px;
  letter-spacing: .05em;
  @include media-queries-up(md) {
    font-size: 1.6rem;
    padding: 15px 10px;
  }
}

.p-sortList__cont {
  background: $prj-gray09;
  color: $prj-gray07;
}

.p-sortList__contInner {
  padding: 20px 15px;
  @include media-queries-up(md) {
    padding: 40px 200px;
  }
}

.p-sortList__itemsWrap {
  margin-bottom: 20px;
  @include media-queries-up(md) {
    margin-bottom: 40px;
  }
}

.p-sortList__item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @include media-queries-up(md) {
    margin-bottom: 20px;
  }
}

.p-sortList__itemName {
  width: 20%;
  margin-right: 10px;
  @include media-queries-up(md) {
    width: 90px;
    margin-right: 10px;
  }
}
.p-sortList__itemSelect {
  width: calc(80% - 10px);
  @include media-queries-up(md) {
    width: 520px;
  }
}

.p-sortListResultsWrap {
  display: none;
  margin-top: 30px;
  @include media-queries-up(md) {
    margin-top: 90px;
  }
  &.is-show {
    display: block;
  }
}
.p-sortListResults__head {
  text-align: center;
  margin-bottom: 30px;
  @include media-queries-up(md) {
    margin-bottom: 60px;
  }
}

.p-sortListResults {
  margin-bottom: -30px;
  @include media-queries-up(md) {
    margin-bottom: -20px;
  }
}
.p-sortListResults__item {
  margin-bottom: 30px;
  @include media-queries-up(md) {
    margin-bottom: 20px;
  }
}

.p-sortCalResultsWrap {
    margin-top: 75px;
    @include media-queries-up(md) {
      display: none;
    }
}

.p-sortCalResults__head {
  font-size: 1.6rem;
  color: $prj-pink02;
  margin-bottom: 35px;
}