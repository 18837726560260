/* badge
========================================================== */
.c-badgeCircle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  background: $prj-pink03;
  color: $white;
  z-index: $z-index5;
  &--white {
    background: $white;
    color: $prj-pink03;
  }
  &--s {
    width: 14px;
    height: 14px;
  }
  &--m {
    width: 18px;
    height: 18px;
    font-size: 1rem;
  }
  &--l {
    width: 21px;
    height: 21px;
    font-size: 1.5rem;
  }
  @include media-queries-up(md) {
    &--md-s {
      width: 21px;
      height: 21px;
    }
    &--md-m {
      width: 27px;
      height: 27px;
      font-size: 1.4rem;
    }
    &--md-l {
      width: 42px;
      height: 42px;
      font-size: 2.6rem;
    }
  }
}