// -----------------------------------------------------------------
// space
// -----------------------------------------------------------------
.u-mg-lr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@for $i from 0 through 30 {
  .u-mg-#{$i*5} {
    margin:#{$i*5}px !important;
  }
  .u-mg-t#{$i*5} {
    margin-top:#{$i*5}px !important;
  }
  .u-mg-b#{$i*5} {
    margin-bottom:#{$i*5}px !important;
  }
  .u-mg-l#{$i*5} {
    margin-left:#{$i*5}px !important;
  }
  .u-mg-r#{$i*5} {
    margin-right:#{$i*5}px !important;
  }
  .u-pd-#{$i*5} {
    padding:#{$i*5}px !important;
  }
  .u-pd-t#{$i*5} {
    padding-top:#{$i*5}px !important;
  }
  .u-pd-b#{$i*5} {
    padding-bottom:#{$i*5}px !important;
  }
  .u-pd-l#{$i*5} {
    padding-left:#{$i*5}px !important;
  }
  .u-pd-r#{$i*5} {
    padding-right:#{$i*5}px !important;
  }
}

@include media-queries-only-down(md) {
  .u-sm-mg-lr-auto {
    margin-left: auto;
    margin-right: auto;
  }
  @for $i from 0 through 30 {
    .u-sm-mg-#{$i*5} {
      margin:#{$i*5}px !important;
    }
    .u-sm-mg-t#{$i*5} {
      margin-top:#{$i*5}px !important;
    }
    .u-sm-mg-b#{$i*5} {
      margin-bottom:#{$i*5}px !important;
    }
    .u-sm-mg-l#{$i*5} {
      margin-left:#{$i*5}px !important;
    }
    .u-sm-mg-r#{$i*5} {
      margin-right:#{$i*5}px !important;
    }
    .u-sm-pd-#{$i*5} {
      padding:#{$i*5}px !important;
    }
    .u-sm-pd-t#{$i*5} {
      padding-top:#{$i*5}px !important;
    }
    .u-sm-pd-b#{$i*5} {
      padding-bottom:#{$i*5}px !important;
    }
    .u-sm-pd-l#{$i*5} {
      padding-left:#{$i*5}px !important;
    }
    .u-sm-pd-r#{$i*5} {
      padding-right:#{$i*5}px !important;
    }
  }
}

@include media-queries-up(md) {
  .u-md-mg-lr-auto {
    margin-left: auto;
    margin-right: auto;
  }
  @for $i from 0 through 30 {
    .u-md-mg-#{$i*5} {
      margin:#{$i*5}px !important;
    }
    .u-md-mg-t#{$i*5} {
      margin-top:#{$i*5}px !important;
    }
    .u-md-mg-b#{$i*5} {
      margin-bottom:#{$i*5}px !important;
    }
    .u-md-mg-l#{$i*5} {
      margin-left:#{$i*5}px !important;
    }
    .u-md-mg-r#{$i*5} {
      margin-right:#{$i*5}px !important;
    }
    .u-md-pd-#{$i*5} {
      padding:#{$i*5}px !important;
    }
    .u-md-pd-t#{$i*5} {
      padding-top:#{$i*5}px !important;
    }
    .u-md-pd-b#{$i*5} {
      padding-bottom:#{$i*5}px !important;
    }
    .u-md-pd-l#{$i*5} {
      padding-left:#{$i*5}px !important;
    }
    .u-md-pd-r#{$i*5} {
      padding-right:#{$i*5}px !important;
    }
  }
}