/* list
========================================================== */

.c-list {
  li {
    margin-bottom: .6em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-list-horizontal {
  li {
    display: inline-block;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.c-list-icons {
  display: inline-block;
  li {
    position: relative;
    margin-bottom: 1em;
    padding-left: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  [class^="c-icon-"]::before,
  [class*=" c-icon-"]::before {
    position: absolute;
    top: .2em;
    left: 0;
    color: $prj-pink03;
    margin-right: .5em;
  }
}

.cardListWrap {

}

.c-cardList {

}

.c-cardListInner {

}
.c-cardList__photo {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 125px;
  height: 75px;
  > img {
    width: 100%;
    height: auto;
  }
}
.c-cardList__block {
  
}