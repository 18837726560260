// -----------------------------------------------------------------
// sizing
// -----------------------------------------------------------------
@for $i from 0 through 20 {
  .u-w-#{$i*5} {
    width:#{$i*5}% !important;
  }
  .u-h-#{$i*5} {
    height:#{$i*5}% !important;
  }
}
.u-mw-100 { max-width: 100% !important; }
.u-mh-100 { max-height: 100% !important; }

@include media-queries-only-down(md) {
  @for $i from 0 through 20 {
    .u-sm-w-#{$i*5} {
      width:#{$i*5}% !important;
    }
    .u-sm-h-#{$i*5} {
      height:#{$i*5}% !important;
    }
  }
  .u-sm-mw-100 {
    max-width: 100% !important;
  }
  .u-sm-mh-100 {
    max-height: 100% !important;
  }
}

@include media-queries-up(md) {
  @for $i from 0 through 20 {
    .u-md-w-#{$i*5} {
      width:#{$i*5}% !important;
    }
    .u-md-h-#{$i*5} {
      height:#{$i*5}% !important;
    }
  }
  .u-md-mw-100 { max-width: 100% !important; }
  .u-md-mh-100 { max-height: 100% !important; }
}