@charset "utf-8";
/* base
========================================================== */
html, body{
  height: 100%;
}

html {
  font-size: 62.5%; // 10px
  box-sizing: border-box;
}

body {
  font-family: $font-default;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.5;
  color: $prj-base-text;
  background-color: #fff;
  margin: 0;
  overflow-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  @include media-queries-up(md) {
    font-size: 1.4rem;
  }
}

* {
  box-sizing: inherit;
  &::before, &::after {
    box-sizing: inherit;
  }
}

a {
  color: $prj-link;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  &:hover, &:active {
    opacity: .8;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ol, ul, li {
  margin:0;
  padding:0;
}

table {
  width: 100%;
  max-width: 100%;
}

table , td, th {
	border: 1px solid $prj-gray14;
  border-collapse: collapse;
}

td, th {
  padding: 10px;
  vertical-align: middle;
}

iframe {
  max-width: 100%;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}